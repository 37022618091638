import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ProductCard from '../productCard/productCard';
import './seePartners.css'
import ImageLoading from 'react-image-lazy-progressive-load-with-progress-bar';
import Logo from '../../Images/Logo-load.png'
import Loading from '../Loading/Loading';

const SeeMorePartners = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const [spinner, setSpinner] = useState(true);
    const params = history.location.search
    const blabla = params.split("?")[1].split("&").reduce((acc , curr) => {
        let temp = curr.split("=")
        acc[temp[0]] = temp[1]
        return {...acc}
    })
    const valid_id = blabla.split("=")[1];

    // Fetch products see more
    const lang = localStorage.getItem("lang")
    const [seeMoresPartners , setSeeMoresPartners] = useState([]);
    const fetchSeeMorePartners = async () => {
        await axios(`https://zafaf-kw.com/dashboard/api/v1/seller?lang=${lang}&category_id=${valid_id}`)
        .then((result) => {
            if (result.data.status) {
                setSpinner(false)
                setSeeMoresPartners(result?.data?.sellers);
            }
        })
    }
    
    useEffect(() => {
        fetchSeeMorePartners();
    } , [lang]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className='see-more'>
            <h3 className='see-more-title'>{t('Success-Partners')}</h3>
            <div className='see-partners-container row'>
            {
                seeMoresPartners?.length ? seeMoresPartners?.map((partners) => (
                    <div className='col-lg-2 col-md-3 col-xs-6 col-6'>
                        <div className='offers-content'>
                            <Link to={`/ServiceProviderDesc?seller_id=${partners.seller_id}`} className='offers-content-img'>
                                <ImageLoading
                                    preview={partners.sellerImage ?? Logo}
                                    src={partners.sellerImage ?? Logo}
                                    lazyLoadScreenOffSet={200}
                                    blur={10}
                                    transitionTime={0.6}
                                    spinnerSize={50}
                                />
                            </Link>
                            <div className='offers-content-details'>
                                <h6>{partners.name}</h6>
                                <p>{partners.desc}</p>
                            </div>
                            <div className="card-rate">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 16.821 16.1">
                                        <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M8.949.559,6.9,4.722,2.3,5.392a1.007,1.007,0,0,0-.557,1.717L5.07,10.347l-.786,4.575a1.006,1.006,0,0,0,1.459,1.06l4.109-2.16,4.109,2.16a1.006,1.006,0,0,0,1.459-1.06l-.786-4.575,3.323-3.238A1.007,1.007,0,0,0,17.4,5.392l-4.593-.67L10.754.559A1.007,1.007,0,0,0,8.949.559Z" transform="translate(-1.441 0.001)" fill="#ffbe44" />
                                </svg>
                                <span>{partners.rating}</span>
                            </div>
                        </div>
                    </div>
                )) 
                : 
                <div className='no-products'>
                    <span>{t('no-products')}</span>
                </div>
            }
            </div>
        </div>
    )
}

export default SeeMorePartners