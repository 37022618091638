import React, { useEffect } from 'react';
import {Map , GoogleApiWrapper} from 'google-maps-react';

export const Maps = (props) => {
    let lat = parseFloat(props?.latitude);
    let long = parseFloat(props?.longitude);
    
    return (
        <Map 
            google={props.google}
            style={{width : "100%" , height : "270px"}}
            zoom = {10}
            initialCenter = {
                {
                    lat : lat,
                    lng : long,
                }
            }
        />
    )
}

export default GoogleApiWrapper({
    apiKey : "AIzaSyBpf9er-Sw2gYTH6L3xoPqRenQF2HKTKzw",
})(Maps)