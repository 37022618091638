import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
// import Logo from '../../Images/Logo.png'
import Logo from '../../Images/zafaf-logo.png'
import DownApp from '../../Images/DownApp.png'
import GooglePlay from '../../Images/google-play.png'
import AppStore from '../../Images/apple.png'
import Profile from '../../Images/profile.png'
import axios from 'axios'
import './navbar.css'
import { RiSearchLine } from "react-icons/ri";
import { BsFilterLeft, BsFilterRight } from "react-icons/bs";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import Sidebar from "../Sidebar/Sidebar";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Filter from "../Filter/Filter";
import Aos from 'aos'
const Navbar = ({options}) => {
      const { t , i18n} = useTranslation();
      const [menuClicked ,  setMenuClicked] = useState(false);
      const [menuExtra ,  setMenuExtra] = useState(false);
      const [isFilterd ,  setIsFilterd] = useState(false);
      const [langList ,  setLangList] = useState(false);
      const [loginList ,  setLoginList] = useState(false);
      const [searchValue ,  setSearchValue] = useState("");
      const { products } = useSelector(state => state.cart);
      const { wishList } = useSelector(state => state.wishList);
      function changeLang(lang) {
            i18n.changeLanguage(lang);
      }
      const [getProfileData , setGetProfileData] = useState({});
      const userID = localStorage.getItem("user_id");

      const [Contacts , setContacts] = useState({});
      const fetchInfo = async () => {
            await axios.get('https://zafaf-kw.com/dashboard/api/v1/info')
            .then((result) => {
                  if (result.data.status) {
                        setContacts(result.data.data)
                  }
            })
      }

      useEffect(() => {
            fetchInfo()
      } , [])

      // Fetch profile data
      const fetchProfileData = async () => {
            await axios.get(`https://zafaf-kw.com/dashboard/api/v1/auth/getProfileData?user_id=${userID}`)
            .then((result) => {
                  if (result.data.status) {
                        setGetProfileData(result.data.user);
                  }
            })
      }
      useEffect(() => {
            fetchProfileData();
      } , []);

      const [maritalIsActive , setMaritalIsActive] = useState()
      const handleMaritalActive = async() => {
            await axios.get(`https://zafaf-kw.com/dashboard/api/v1/martileActive`)
            .then((result) => {
                  if (result.data.status) {
                        setMaritalIsActive(result.data.martileActive);
                  }
            })
      }
      useEffect(() => {
            handleMaritalActive()
      } , [])
      
      const [scrollTop, setScrollTop] = useState(0);
      useEffect(() => {
            const handleScroll = event => {
                  setScrollTop(window.scrollY);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);
      
      useEffect(() => {
            Aos.init({duration : 1000});
      } , [scrollTop]);

      let myUrlPath = window?.location?.pathname

	return (
            <>
                  <header className="header-desktop fixed-navbar-top" id="100">
                        {menuClicked && <Sidebar maritalIsActive={maritalIsActive} menuClicked={menuClicked} setMenuClicked ={setMenuClicked}/>}
                        {isFilterd && <Filter OurOptions={options} isFilterd={isFilterd} setIsFilterd={setIsFilterd} />}
                        <div className="my-navbar">
                              <div className="right">
                                    <div className="side-menu" onClick={() => setMenuClicked(!menuClicked)}>
                                          {document.documentElement.dir === 'ltr' ? <BsFilterLeft></BsFilterLeft> : <BsFilterRight></BsFilterRight>}
                                          <span>{t("menu")}</span>
                                    </div>
                                    <Link to='/' className="navbar-logo">
                                          <img src={Logo} alt="logo"/>
                                    </Link>
                              </div>
                              <form className="navbar-search">
                                    <input 
                                          type="text" 
                                          placeholder={t('search')} 
                                          value={searchValue} 
                                          onChange={(e) => setSearchValue(e.target.value)} 
                                    />
                                    <Link to={`/searchresults?q=${searchValue}`} >
                                          <button className={document.documentElement.dir === 'ltr' ? 'navbar-search-btn-en' : 'navbar-search-btn-ar'} type="submit">
                                                <span>
                                                      <RiSearchLine></RiSearchLine>
                                                </span>
                                          </button>
                                    </Link>
                              </form>
                              <div className="left">
                                    <div className="navbar-settings">
                                          <Link to="/"  onClick={() => setIsFilterd(!isFilterd)}>
                                                <svg id="Group_16363" data-name="Group 16363" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.436 23.326">
                                                      <path id="Path_15007" data-name="Path 15007" d="M482,77.492Z" transform="translate(-454.564 -76.501)"/>
                                                      <path id="Path_15008" data-name="Path 15008" d="M88.467,82.444h-15a3.095,3.095,0,0,0-5.868,0H65.252a.985.985,0,1,0,0,1.97h2.342a3.095,3.095,0,0,0,5.868,0h15a.985.985,0,1,0,0-1.97Zm-17.939,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,70.528,84.555Z" transform="translate(-64.267 -80.082)"/>
                                                      <path id="Path_15009" data-name="Path 15009" d="M88.467,210.977H86.125a3.095,3.095,0,0,0-5.868,0h-15a.985.985,0,1,0,0,1.97h15a3.095,3.095,0,0,0,5.868,0h2.342a.985.985,0,1,0,0-1.97Zm-5.276,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,83.191,213.088Z" transform="translate(-64.267 -200.174)"/>
                                                      <path id="Path_15010" data-name="Path 15010" d="M88.467,339.51H77.683a3.095,3.095,0,0,0-5.868,0H65.252a.985.985,0,1,0,0,1.97h6.563a3.095,3.095,0,0,0,5.868,0H88.467a.985.985,0,1,0,0-1.97Zm-13.718,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,74.749,341.621Z" transform="translate(-64.267 -320.265)"/>
                                                </svg>
                                          </Link>
                                    </div>
                                    <div className="navbar-lang">
                                          <div className="navbar-lang-btn" onClick={() => setLangList(!langList)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29 29">
                                                      <path id="Path_17338" data-name="Path 17338" d="M32.368,27.57A14.432,14.432,0,0,0,34.5,20h0a14.432,14.432,0,0,0-2.132-7.57l-.008-.012a14.5,14.5,0,0,0-24.72,0l-.008.013a14.5,14.5,0,0,0,0,15.14l.008.013a14.5,14.5,0,0,0,24.719,0ZM21.853,32.015a3.524,3.524,0,0,1-.941.65,2.125,2.125,0,0,1-1.824,0,4.438,4.438,0,0,1-1.645-1.447,12.96,12.96,0,0,1-1.873-4.063q2.213-.136,4.43-.139t4.43.139a16.357,16.357,0,0,1-.794,2.187,8.408,8.408,0,0,1-1.782,2.674Zm-14.7-11.2h5.849a28.773,28.773,0,0,0,.529,4.86q-2.4.211-4.784.58a12.783,12.783,0,0,1-1.593-5.439Zm1.593-7.069q2.385.37,4.786.58a28.724,28.724,0,0,0-.53,4.86H7.158a12.783,12.783,0,0,1,1.593-5.439Zm9.4-5.761a3.523,3.523,0,0,1,.941-.65,2.125,2.125,0,0,1,1.824,0,4.438,4.438,0,0,1,1.645,1.447,12.96,12.96,0,0,1,1.873,4.063q-2.213.136-4.43.139t-4.43-.139a16.354,16.354,0,0,1,.794-2.187,8.408,8.408,0,0,1,1.782-2.674Zm14.7,11.2H26.994a28.776,28.776,0,0,0-.529-4.86q2.4-.211,4.784-.58A12.783,12.783,0,0,1,32.842,19.185ZM15.173,25.548a26.946,26.946,0,0,1-.538-4.733H25.364a26.94,26.94,0,0,1-.537,4.733q-2.411-.158-4.828-.161T15.173,25.548Zm9.653-11.1a26.948,26.948,0,0,1,.538,4.733H14.636a26.941,26.941,0,0,1,.537-4.733q2.411.158,4.828.161T24.826,14.452Zm2.167,6.362h5.849a12.783,12.783,0,0,1-1.593,5.439q-2.385-.37-4.786-.58a28.722,28.722,0,0,0,.53-4.86Zm3.279-8.568q-2.085.3-4.183.476a18.579,18.579,0,0,0-.97-2.736,11.561,11.561,0,0,0-1.349-2.3,12.868,12.868,0,0,1,6.5,4.555ZM10.9,10.9a12.866,12.866,0,0,1,5.329-3.207c-.031.04-.062.077-.092.118a14.873,14.873,0,0,0-2.223,4.914q-2.1-.178-4.186-.477A12.976,12.976,0,0,1,10.9,10.9ZM9.728,27.753q2.085-.3,4.183-.476a18.579,18.579,0,0,0,.97,2.736,11.562,11.562,0,0,0,1.349,2.3,12.868,12.868,0,0,1-6.5-4.555ZM29.1,29.1a12.866,12.866,0,0,1-5.329,3.207c.031-.04.062-.077.092-.118a14.873,14.873,0,0,0,2.223-4.914q2.1.178,4.186.477A12.978,12.978,0,0,1,29.1,29.1Z" transform="translate(-5.5 -5.5)"/>
                                                </svg>
                                          </div>
                                          { langList &&
                                                <ul className="navbar-lang-list" id={document.documentElement.dir === 'ltr' ? 'navbar-lang-list-en' : 'navbar-lang-list-ar'}>
                                                      <li onClick={() => {
                                                            changeLang("ar")
                                                            localStorage.setItem("dir" , 'rtl')
                                                            document.documentElement.dir = localStorage.getItem("dir")
                                                            document.documentElement.lang = 'ar'
                                                            localStorage.setItem("lang" , document.documentElement.lang)
                                                            localStorage.getItem('lang') === 'ar' ?
                                                            document.documentElement.dir = localStorage.getItem("dir") 
                                                            : 
                                                            document.documentElement.dir = localStorage.getItem("dir")
                                                            setLangList(!langList)
                                                            setMenuExtra(!menuExtra)
                                                            window.location.reload()
                                                      }}
                                                      >
                                                            <span>عربى</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 36 36" aria-hidden="true" role="img" className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#007A3D" d="M32 5H4a4 4 0 0 0-4 4v4.5h36V9a4 4 0 0 0-4-4z"/><path fill="#CE1126" d="M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-4.5H0V27z"/><path fill="#EEE" d="M0 13.5h36v9H0z"/><path fill="#141414" d="M1.205 6.138A3.993 3.993 0 0 0 0 9v18c0 1.122.462 2.135 1.205 2.862L9 22.5v-9L1.205 6.138z"/></svg>
                                                      </li>

                                                      <li onClick={() => {
                                                            // document.documentElement.dir = 'ltr'
                                                            localStorage.setItem("dir" , 'ltr')
                                                            document.documentElement.dir = localStorage.getItem("dir")
                                                            document.documentElement.lang = 'en'
                                                            localStorage.setItem("lang" , document.documentElement.lang)
                                                            localStorage.getItem('lang') === 'en' ? document.documentElement.dir = localStorage.getItem("dir") : document.documentElement.dir = localStorage.getItem("dir")
                                                            changeLang("en")
                                                            setLangList(!langList)
                                                            setMenuExtra(!menuExtra)
                                                            window.location.reload()
                                                      }}
                                                      >
                                                            <span>English</span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 36 36" aria-hidden="true" role="img" className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#00247D" d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"/><path fill="#CF1B2B" d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"/><path fill="#EEE" d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"/><path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"/></svg>
                                                      </li>
                                                </ul>
                                          }
                                    </div>
                                    <div className="navbar-profile">
                                          <Link 
                                                to={ localStorage.getItem('user_id') && localStorage.getItem('user_id') ? `/profile` : `/login`}
                                                className="navbar-lang-btn"
                                                title={localStorage.getItem('user_id') ? `${getProfileData?.name || 'Profile'}` : 'Login'} 
                                                onClick={ () => setLoginList(!loginList) }
                                          >
                                                { 
                                                      localStorage.getItem('user_id') ? 
                                                      <img src={getProfileData?.image || Profile} alt=""/> 
                                                      : 
                                                      <BiUser></BiUser>
                                                }
                                          </Link>
                                    </div>
                                    <div className="navbar-favourites">
                                          <Link to='./favourites'>
                                                {
                                                      wishList.length > 0 ? 
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 35.594 31.365">
                                                            <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="red" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                      </svg>
                                                      : 
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 35.594 31.365">
                                                            <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                      </svg>
                                                }
                                          </Link>
                                    </div>
                                    <div className="navbar-cart">
                                          <Link to='/cart'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 21.675">
                                                      <g id="Bag" transform="translate(-0.316)">
                                                            <path id="Combined_Shape" data-name="Combined Shape" d="M13.89,16H5.139C3.181,16,1.826,15.546,1,14.612c-.891-1-1.18-2.532-.883-4.675L.933,3.328C1.293,1.244,2.5,0,4.151,0H14.868A2.8,2.8,0,0,1,16.8.78a4.7,4.7,0,0,1,1.3,2.548l.812,6.609a5.479,5.479,0,0,1-1,4.557A5.193,5.193,0,0,1,13.89,16ZM12.546,3.551a.972.972,0,1,0,.932.971A.954.954,0,0,0,12.546,3.551Zm-6.11,0a.972.972,0,1,0,.933.971A.954.954,0,0,0,6.436,3.551Z" transform="translate(0.316 5.675)" fill="#fff"/>
                                                            <path id="Path_34167" d="M10.855,5.2a.546.546,0,0,1-.047.246H9.243A.706.706,0,0,1,9.2,5.2a3.792,3.792,0,0,0-7.583,0,.706.706,0,0,1,0,.246H.011a.706.706,0,0,1,0-.246,5.442,5.442,0,0,1,10.873,0Z" transform="translate(4.083)" fill="#fff" opacity="0.4"/>
                                                      </g>
                                                </svg>
                                          </Link>
                                          {localStorage.getItem('user_id') && products.length > 0 && <span className="cart-notifications">{products.length}</span>}
                                    </div>
                              </div>
                        </div>
                        {langList && <div className="above-all" onClick={() => setLangList(false)}></div>}
                        <div className="my-navs">
                              <NavLink exact activeClassName="nav-active" to="/">{t('home')}</NavLink>
                              <NavLink activeClassName="nav-active" to="/about">{t('about')}</NavLink>
                              <NavLink activeClassName="nav-active" to="/allsections">{t('sections')}</NavLink>
                              <NavLink activeClassName="nav-active" to="/offers">{t('offers')}</NavLink>
                              <NavLink activeClassName="nav-active" to="/contact">{t('contact')}</NavLink>
                              <NavLink activeClassName="nav-active" to="/specialOrder">{t('special_orders')}</NavLink>
                              {
                                    maritalIsActive === 1 ? 
                                    <NavLink to="/maritalCounselling">
                                          {t('marital_counseling')}
                                    </NavLink>
                                    : null
                              }
                        </div>
                  </header>
                  <header className="header-responsive fixed-navbar-top">
                        {menuClicked && <Sidebar maritalIsActive={maritalIsActive} menuClicked={menuClicked} setMenuClicked ={setMenuClicked}/>}
                        {isFilterd && <Filter OurOptions={options} isFilterd={isFilterd} setIsFilterd={setIsFilterd} />}
                        {
                        myUrlPath === '/' && <div className="download-our-app" data-aos="fade-down">
                              <div className="download-our-app-info">
                                    <img src={DownApp} alt=""/>
                                    <div>
                                          <h5>{t('Down-app')}</h5>
                                          <span>{t('Down-app-text')}</span>
                                    </div>
                              </div>
                              <div className="download-our-app-btn">
                                    <a href={Contacts?.google_play}><img src={GooglePlay} alt=""/></a>
                                    <a href={Contacts?.apple_play}><img src={AppStore} alt=""/></a>
                              </div>
                        </div>
                        }
                        <div className="my-navbar">
                              <div className="top-res">
                                    <div className="side-menu" onClick = {
                                          () => {
                                                setMenuClicked(!menuClicked);
                                                setMenuExtra(false);
                                          }}
                                    >
                                          {document.documentElement.dir === 'ltr' ? <BsFilterLeft></BsFilterLeft> : <BsFilterRight></BsFilterRight>}
                                          <span>{t("menu")}</span>
                                    </div>
                                    <Link to='/' className="navbar-logo">
                                          <img src={Logo} alt="logo"/>
                                    </Link>
                                    <div className="extra-features">
                                          <span 
                                                onClick={ () => {
                                                      setMenuExtra(!menuExtra);
                                                      setMenuClicked(false);
                                                }}
                                          >
                                                <MdOutlinePlaylistAdd></MdOutlinePlaylistAdd>
                                          </span>
                                          {
                                                menuExtra && 
                                                <div className="extra-features-list">
                                                      <Link to="/" className="navbar-settings" onClick={
                                                            () => {
                                                                  setIsFilterd(!isFilterd)
                                                                  setMenuExtra(!menuExtra)
                                                            }}
                                                      >
                                                            <svg id="Group_16363" data-name="Group 16363" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 27.436 23.326">
                                                                  <path id="Path_15007" data-name="Path 15007" d="M482,77.492Z" transform="translate(-454.564 -76.501)"/>
                                                                  <path id="Path_15008" data-name="Path 15008" d="M88.467,82.444h-15a3.095,3.095,0,0,0-5.868,0H65.252a.985.985,0,1,0,0,1.97h2.342a3.095,3.095,0,0,0,5.868,0h15a.985.985,0,1,0,0-1.97Zm-17.939,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,70.528,84.555Z" transform="translate(-64.267 -80.082)"/>
                                                                  <path id="Path_15009" data-name="Path 15009" d="M88.467,210.977H86.125a3.095,3.095,0,0,0-5.868,0h-15a.985.985,0,1,0,0,1.97h15a3.095,3.095,0,0,0,5.868,0h2.342a.985.985,0,1,0,0-1.97Zm-5.276,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,83.191,213.088Z" transform="translate(-64.267 -200.174)"/>
                                                                  <path id="Path_15010" data-name="Path 15010" d="M88.467,339.51H77.683a3.095,3.095,0,0,0-5.868,0H65.252a.985.985,0,1,0,0,1.97h6.563a3.095,3.095,0,0,0,5.868,0H88.467a.985.985,0,1,0,0-1.97Zm-13.718,2.11a1.125,1.125,0,1,1,1.125-1.125A1.127,1.127,0,0,1,74.749,341.621Z" transform="translate(-64.267 -320.265)"/>
                                                            </svg>
                                                      </Link>
                                                      <div className="navbar-lang">
                                                            <div className="navbar-lang-btn" onClick={() => {
                                                                  setLangList(!langList)
                                                                  // setMenuExtra(!menuExtra)
                                                            }}>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29 29">
                                                                        <path id="Path_17338" data-name="Path 17338" d="M32.368,27.57A14.432,14.432,0,0,0,34.5,20h0a14.432,14.432,0,0,0-2.132-7.57l-.008-.012a14.5,14.5,0,0,0-24.72,0l-.008.013a14.5,14.5,0,0,0,0,15.14l.008.013a14.5,14.5,0,0,0,24.719,0ZM21.853,32.015a3.524,3.524,0,0,1-.941.65,2.125,2.125,0,0,1-1.824,0,4.438,4.438,0,0,1-1.645-1.447,12.96,12.96,0,0,1-1.873-4.063q2.213-.136,4.43-.139t4.43.139a16.357,16.357,0,0,1-.794,2.187,8.408,8.408,0,0,1-1.782,2.674Zm-14.7-11.2h5.849a28.773,28.773,0,0,0,.529,4.86q-2.4.211-4.784.58a12.783,12.783,0,0,1-1.593-5.439Zm1.593-7.069q2.385.37,4.786.58a28.724,28.724,0,0,0-.53,4.86H7.158a12.783,12.783,0,0,1,1.593-5.439Zm9.4-5.761a3.523,3.523,0,0,1,.941-.65,2.125,2.125,0,0,1,1.824,0,4.438,4.438,0,0,1,1.645,1.447,12.96,12.96,0,0,1,1.873,4.063q-2.213.136-4.43.139t-4.43-.139a16.354,16.354,0,0,1,.794-2.187,8.408,8.408,0,0,1,1.782-2.674Zm14.7,11.2H26.994a28.776,28.776,0,0,0-.529-4.86q2.4-.211,4.784-.58A12.783,12.783,0,0,1,32.842,19.185ZM15.173,25.548a26.946,26.946,0,0,1-.538-4.733H25.364a26.94,26.94,0,0,1-.537,4.733q-2.411-.158-4.828-.161T15.173,25.548Zm9.653-11.1a26.948,26.948,0,0,1,.538,4.733H14.636a26.941,26.941,0,0,1,.537-4.733q2.411.158,4.828.161T24.826,14.452Zm2.167,6.362h5.849a12.783,12.783,0,0,1-1.593,5.439q-2.385-.37-4.786-.58a28.722,28.722,0,0,0,.53-4.86Zm3.279-8.568q-2.085.3-4.183.476a18.579,18.579,0,0,0-.97-2.736,11.561,11.561,0,0,0-1.349-2.3,12.868,12.868,0,0,1,6.5,4.555ZM10.9,10.9a12.866,12.866,0,0,1,5.329-3.207c-.031.04-.062.077-.092.118a14.873,14.873,0,0,0-2.223,4.914q-2.1-.178-4.186-.477A12.976,12.976,0,0,1,10.9,10.9ZM9.728,27.753q2.085-.3,4.183-.476a18.579,18.579,0,0,0,.97,2.736,11.562,11.562,0,0,0,1.349,2.3,12.868,12.868,0,0,1-6.5-4.555ZM29.1,29.1a12.866,12.866,0,0,1-5.329,3.207c.031-.04.062-.077.092-.118a14.873,14.873,0,0,0,2.223-4.914q2.1.178,4.186.477A12.978,12.978,0,0,1,29.1,29.1Z" transform="translate(-5.5 -5.5)"/>
                                                                  </svg>
                                                            </div>
                                                            { langList &&
                                                                  <ul className="navbar-lang-list" id={document.documentElement.dir === 'ltr' ? 'navbar-lang-list-en' : 'navbar-lang-list-ar'}>
                                                                        <li onClick={() => {
                                                                              changeLang("ar")
                                                                              localStorage.setItem("dir" , 'rtl')
                                                                              document.documentElement.dir = localStorage.getItem("dir")
                                                                              document.documentElement.lang = 'ar'
                                                                              localStorage.setItem("lang" , document.documentElement.lang)
                                                                              localStorage.getItem('lang') === 'ar' ?
                                                                              document.documentElement.dir = localStorage.getItem("dir") 
                                                                              : 
                                                                              document.documentElement.dir = localStorage.getItem("dir")
                                                                              setLangList(!langList)
                                                                              setMenuExtra(!menuExtra)
                                                                              window.location.reload()
                                                                        }}
                                                                        >
                                                                              <span>عربى</span>
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 36 36" aria-hidden="true" role="img" className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#007A3D" d="M32 5H4a4 4 0 0 0-4 4v4.5h36V9a4 4 0 0 0-4-4z"/><path fill="#CE1126" d="M0 27a4 4 0 0 0 4 4h28a4 4 0 0 0 4-4v-4.5H0V27z"/><path fill="#EEE" d="M0 13.5h36v9H0z"/><path fill="#141414" d="M1.205 6.138A3.993 3.993 0 0 0 0 9v18c0 1.122.462 2.135 1.205 2.862L9 22.5v-9L1.205 6.138z"/></svg>
                                                                        </li>
                  
                                                                        <li onClick={() =>{
                                                                              // document.documentElement.dir = 'ltr'
                                                                              localStorage.setItem("dir" , 'ltr')
                                                                              document.documentElement.dir = localStorage.getItem("dir")
                                                                              document.documentElement.lang = 'en'
                                                                              localStorage.setItem("lang" , document.documentElement.lang)
                                                                              localStorage.getItem('lang') === 'en' ? document.documentElement.dir = localStorage.getItem("dir") : document.documentElement.dir = localStorage.getItem("dir")
                                                                              changeLang("en")
                                                                              setLangList(!langList)
                                                                              setMenuExtra(!menuExtra)
                                                                              window.location.reload()
                                                                        }}
                                                                        >
                                                                              <span>English</span>
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 36 36" aria-hidden="true" role="img" className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet"><path fill="#00247D" d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"/><path fill="#CF1B2B" d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"/><path fill="#EEE" d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"/><path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"/></svg>
                                                                        </li>
                                                                  </ul>
                                                            }
                                                      </div>
                                                      <Link to={ localStorage.getItem('user_id') && localStorage.getItem('user_id') ? `/profile` : `/login`}
                                                            title={localStorage.getItem('user_id') ? `${getProfileData?.name || 'Profile'}` : 'Login'} 
                                                            className="navbar-profile" onClick={() => setMenuExtra(!menuExtra)}
                                                      >
                                                            { localStorage.getItem('user_id') && localStorage.getItem('user_id') ? 
                                                            <img src={getProfileData?.image || Profile} alt=""/> : <BiUser></BiUser>}
                                                      </Link>
                                                      <Link to='./favourites' className="navbar-favourites" onClick={() => setMenuExtra(!menuExtra)}>
                                                      {
                                                            wishList.length > 0 ? 
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 35.594 31.365">
                                                                  <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="red" stroke="red" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                            </svg>
                                                            : 
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 35.594 31.365">
                                                                  <path id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                            </svg>
                                                      }
                                                      </Link>
                                                      <Link to='/cart' className="navbar-cart" onClick={() => setMenuExtra(!menuExtra)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 21.675">
                                                                  <g id="Bag" transform="translate(-0.316)">
                                                                  <path id="Combined_Shape" data-name="Combined Shape" d="M13.89,16H5.139C3.181,16,1.826,15.546,1,14.612c-.891-1-1.18-2.532-.883-4.675L.933,3.328C1.293,1.244,2.5,0,4.151,0H14.868A2.8,2.8,0,0,1,16.8.78a4.7,4.7,0,0,1,1.3,2.548l.812,6.609a5.479,5.479,0,0,1-1,4.557A5.193,5.193,0,0,1,13.89,16ZM12.546,3.551a.972.972,0,1,0,.932.971A.954.954,0,0,0,12.546,3.551Zm-6.11,0a.972.972,0,1,0,.933.971A.954.954,0,0,0,6.436,3.551Z" transform="translate(0.316 5.675)" fill="#fff"/>
                                                                  <path id="Path_34167" d="M10.855,5.2a.546.546,0,0,1-.047.246H9.243A.706.706,0,0,1,9.2,5.2a3.792,3.792,0,0,0-7.583,0,.706.706,0,0,1,0,.246H.011a.706.706,0,0,1,0-.246,5.442,5.442,0,0,1,10.873,0Z" transform="translate(4.083)" fill="#fff" opacity="0.4"/>
                                                                  </g>
                                                            </svg>
                                                            {localStorage.getItem('user_id') && products.length > 0 && <span className="cart-notifications">{products.length}</span>}
                                                      </Link>
                                                </div>
                                          }
                                    </div>
                                    {menuExtra && <div className="above-all" onClick={() => setMenuExtra(false)}></div>}
                                    {langList && <div className="above-all" onClick={() => setLangList(false)}></div>}
                              </div>
                              <form className="navbar-search">
                                    <input 
                                          type="text" 
                                          placeholder={t('search')} 
                                          value={searchValue} 
                                          onChange={(e) => setSearchValue(e.target.value)} 
                                    />
                                          <Link to={`/searchresults?q=${searchValue}`}>
                                                <button className={document.documentElement.dir === 'ltr' ? 'navbar-search-btn-en' : 'navbar-search-btn-ar'} type="submit">
                                                      <span>
                                                            <RiSearchLine></RiSearchLine>
                                                      </span>
                                                </button>
                                          </Link>
                                    
                              </form>
                        </div>
                        <div className="my-navs">
                              <Link to="/">{t('home')}</Link>
                              <Link to="/about">{t('about')}</Link>
                              <Link to="/sections">{t('sections')}</Link>
                              <Link to="/offers">{t('offers')}</Link>
                              <Link to="/contact">{t('contact')}</Link>
                              <Link to="/specialOrder">{t('special_orders')}</Link>
                        </div>
                  </header>
            </>
	);
};

export default Navbar;