import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './productCard.css'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, fetchCart } from '../../store/cartSlice';
import { Link } from 'react-router-dom';
import { addProductToWishList, deleteProductFromWishList, fetchWishlist } from '../../store/wishListSlice';
import ImageLoading from 'react-image-lazy-progressive-load-with-progress-bar';
import Logo from '../../Images/Logo-load.png'

const ProductCard = ({ product: { image, productImage, id, name , productName , nameProduct, seller, details, unit_price, phone , rating, discount , type , totalPrice , price , reviewCount , wishlist, ...product } }) => {
      const { t } = useTranslation();
      const { wishList } = useSelector(state => state.wishList)
      const dispatch = useDispatch();
      const firstRender = useRef(true);

      // need to set the same key from locale storage for the user ID
      const userID = localStorage.getItem("user_id");
      const cardQuantity = 1;
      const inWishList = useMemo(() => wishList.length > 0 ? wishList.filter(item => item?.id === id || item?.product_id === id).length > 0 : false, [id, wishList])
      const [wishMessage, setWishMessage] = useState(false);

      // handle add to cart
      const addToCart = () => {
            dispatch(addProduct({ user_id: parseInt(userID), product_id: id, quantity: cardQuantity, callBack: () => dispatch(fetchCart(parseInt(userID))) }))
      }
      const handleMsgText = useCallback(() => {
            setWishMessage(prev => true)
            setTimeout(() => setWishMessage(prev => false), 1500)
      }, [inWishList])

      // handle add to wish
      const addToWish = () => {
            firstRender.current = false;
            if(inWishList) {
                  dispatch(deleteProductFromWishList({
                        user_id: parseInt(userID), product_id: id, callBack: () => {
                              dispatch(fetchWishlist(parseInt(userID)))
                        }
                  }))
                  return
            }
            dispatch(addProductToWishList({
                  user_id: parseInt(userID), product_id: id, callBack: () => {
                        dispatch(fetchWishlist(parseInt(userID)))
                  }
            }))
      }

      useEffect(() => {
            if(!firstRender.current) handleMsgText()
      }, [handleMsgText]);

      return (
            <>
                  {wishMessage && (inWishList ? (<div className="product-added">{t('product-added-success')}</div>) : (<div className="product-removed">{t('product-removed-success')}</div>))}                  
                  <div className='service-content' dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}>
                        <Link to={`/productDescription?id=${id}`}>
                              <div className='service-content-img'>
                                    {
                                          <ImageLoading
                                                preview={image ?? productImage ?? product?.product?.productImage ?? Logo}
                                                src={image ?? productImage ?? product?.product?.productImage ?? Logo}
                                                lazyLoadScreenOffSet={200}
                                                blur={10}
                                                transitionTime={0.6}
                                                spinnerSize={50}
                                          />
                                          
                                    }
                              </div>
                        </Link>

                        <div className='service-content-details'>
                              <h6>{name ?? productName ?? nameProduct ?? product?.products?.name }</h6>
                              {seller ? <h5>{seller}</h5> : <h5>{t('default-name')}</h5>}
                              <p className='service-content-description'>{details}</p>
                        </div>
                        <div className='service-content-data'>
                              <div>
                                    <span>{totalPrice} {t('currency')}</span>
                                    {discount > 0 && <del>{unit_price} {t('currency')}</del>}
                              </div>
                              <div>
                                    {
                                          wishlist === 0 && 
                                          <div className="navbar-favorites" onClick={addToWish}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 35.594 31.365">
                                                      <path className={inWishList ? 'navbar-favorites-active' : ''} id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                </svg>
                                          </div> 
                                    }
                                    {
                                          wishlist === 1 && 
                                          <div className="navbar-favorites " onClick={addToWish}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="red" stroke= "red" width="20" height="20" viewBox="0 0 35.594 31.365">
                                                      <path className={inWishList ? 'navbar-favorites-active' : ''} id="Icon_feather-heart" data-name="Icon feather-heart" d="M32.865,7.049a8.708,8.708,0,0,0-12.317,0L18.87,8.727,17.191,7.049A8.71,8.71,0,1,0,4.874,19.366l1.678,1.678L18.87,33.362,31.187,21.045l1.678-1.678a8.708,8.708,0,0,0,0-12.317Z" transform="translate(-1.073 -3.247)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
                                                </svg>
                                          </div>
                                    }
                                    
                                    <div className="navbar-cart">
                                          {
                                                type === 0 && 
                                                <button onClick={addToCart} title={document.documentElement.dir === 'rtl' ? 'أضف إلى السله' : 'Add to cart'}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 21.675">
                                                            <g id="Bag" transform="translate(-0.316)">
                                                                  <path id="Combined_Shape" data-name="Combined Shape" d="M13.89,16H5.139C3.181,16,1.826,15.546,1,14.612c-.891-1-1.18-2.532-.883-4.675L.933,3.328C1.293,1.244,2.5,0,4.151,0H14.868A2.8,2.8,0,0,1,16.8.78a4.7,4.7,0,0,1,1.3,2.548l.812,6.609a5.479,5.479,0,0,1-1,4.557A5.193,5.193,0,0,1,13.89,16ZM12.546,3.551a.972.972,0,1,0,.932.971A.954.954,0,0,0,12.546,3.551Zm-6.11,0a.972.972,0,1,0,.933.971A.954.954,0,0,0,6.436,3.551Z" transform="translate(0.316 5.675)" fill="#fff" />
                                                                  <path id="Path_34167" d="M10.855,5.2a.546.546,0,0,1-.047.246H9.243A.706.706,0,0,1,9.2,5.2a3.792,3.792,0,0,0-7.583,0,.706.706,0,0,1,0,.246H.011a.706.706,0,0,1,0-.246,5.442,5.442,0,0,1,10.873,0Z" transform="translate(4.083)" fill="#fff" opacity="0.4" />
                                                            </g>
                                                      </svg>
                                                </button>
                                          }
                                          {
                                                type === 1 && 
                                                <button onClick={addToCart} title={document.documentElement.dir === 'rtl' ? 'أضف إلى السله' : 'Add to cart'}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 19 21.675">
                                                            <g id="Bag" transform="translate(-0.316)">
                                                                  <path id="Combined_Shape" data-name="Combined Shape" d="M13.89,16H5.139C3.181,16,1.826,15.546,1,14.612c-.891-1-1.18-2.532-.883-4.675L.933,3.328C1.293,1.244,2.5,0,4.151,0H14.868A2.8,2.8,0,0,1,16.8.78a4.7,4.7,0,0,1,1.3,2.548l.812,6.609a5.479,5.479,0,0,1-1,4.557A5.193,5.193,0,0,1,13.89,16ZM12.546,3.551a.972.972,0,1,0,.932.971A.954.954,0,0,0,12.546,3.551Zm-6.11,0a.972.972,0,1,0,.933.971A.954.954,0,0,0,6.436,3.551Z" transform="translate(0.316 5.675)" fill="#fff" />
                                                                  <path id="Path_34167" d="M10.855,5.2a.546.546,0,0,1-.047.246H9.243A.706.706,0,0,1,9.2,5.2a3.792,3.792,0,0,0-7.583,0,.706.706,0,0,1,0,.246H.011a.706.706,0,0,1,0-.246,5.442,5.442,0,0,1,10.873,0Z" transform="translate(4.083)" fill="#fff" opacity="0.4" />
                                                            </g>
                                                      </svg>
                                                </button>
                                          }
                                          {
                                                type === 2 && 
                                                <button 
                                                      title={document.documentElement.dir === 'rtl' ? 'إحجز الان' : 'Book now'}
                                                >
                                                      <Link to={`/productDescription?id=${id}`}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24" fill="none">
                                                                  <path d="M8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12H8Z" fill="#fff"/>
                                                                  <path d="M7 17C7 16.4477 7.44772 16 8 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H8C7.44772 18 7 17.5523 7 17Z" fill="#fff"/>
                                                                  <path fillRule="evenodd" clipRule="evenodd" d="M8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V4.10002C3.71776 4.56329 2 6.58104 2 9V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V9C22 6.58104 20.2822 4.56329 18 4.10002V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3ZM20 10H4V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V10ZM4.17071 8C4.58254 6.83481 5.69378 6 7 6H17C18.3062 6 19.4175 6.83481 19.8293 8H4.17071Z" fill="#fff"/>
                                                            </svg>
                                                      </Link>
                                                      
                                                </button>
                                          }
                                          {
                                                type === 3 && 
                                                <Link to={`/productDescription?id=${id}`} title={document.documentElement.dir === 'rtl' ? 'إتصل الان' : 'call now'}>
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="-10.5 0 65 65" version="1.1">
                                                            <g id="Page-1" stroke="none" strokeWidth="0" fill="none" fillRule="evenodd" >
                                                                  <path d="M1.1,18.2 C1.1,18.2 3.6,25.1 9.9,38.2 C9.9,38.2 9.9,38.2 9.9,38.3 C16.3,51.4 20.4,57.3 20.4,57.3 C24.6,61.8 31.6,63.8 39.1,60.2 C43.2,58.2 42.5,53.5 38.7,45.7 L38.7,45.7 C35.3,38.7 31.5,38.5 29,40.7 C28.5,41.1 26.9,42.6 26.8,43.1 C26.8,43.1 25.3,45.6 19.6,33.7 C13.9,21.9 16.2,22.5 16.2,22.5 C16.6,22.6 18.8,22.9 19.4,22.7 C22.6,22 25.2,18.6 21.5,11 L21.5,11 C17.7,3.2 14.5,-0.3 10.4,1.7 C4.2,4.6 0.2,12.1 1.1,18.2 L1.1,18.2 Z" id="Phone-call" stroke="#6B6C6E" strokeWidth="2" />
                                                            </g>
                                                      </svg>
                                                </Link>
                                          }
                                          
                                    </div>
                              </div>
                        </div>
                        <div className="card-rate">
                              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 16.821 16.1">
                                    <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M8.949.559,6.9,4.722,2.3,5.392a1.007,1.007,0,0,0-.557,1.717L5.07,10.347l-.786,4.575a1.006,1.006,0,0,0,1.459,1.06l4.109-2.16,4.109,2.16a1.006,1.006,0,0,0,1.459-1.06l-.786-4.575,3.323-3.238A1.007,1.007,0,0,0,17.4,5.392l-4.593-.67L10.754.559A1.007,1.007,0,0,0,8.949.559Z" transform="translate(-1.441 0.001)" fill="#ffbe44" />
                              </svg>
                              <span>{rating}</span>
                        </div>
                        {
                        discount > 0 &&
                        <div className="card-discount">
                              <span>{t('discount')} {discount} %</span>
                        </div>
                        }
                        
                  </div>
            </>
      )
}

export default ProductCard;