import React, { useEffect, useState } from 'react'
import Phone from '../../Images/phone.png'
import Email from '../../Images/mail.png'
import Logo from '../../Images/zafaf-logo.png'
import './Footer.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SocialMedia from '../SocialMedia/SocialMedia'
import axios from 'axios'
import ReactWhatsapp from 'react-whatsapp';

const Footer = () => {
      const { t } = useTranslation();
      const [Contacts , setContacts] = useState({});
      const fetchInfo = async () => {
            await axios.get('https://zafaf-kw.com/dashboard/api/v1/info')
            .then((result) => {
                  if (result.data.status) {
                        setContacts(result.data.data)
                  }
            })
      }

      useEffect(() => {
            fetchInfo()
      } , [])

      return (
            <div className='footer'>
                  <div className="footer__container">
                        <div className="footer-content">
                              <div className="footer__details col-lg-3 col-md-3 col-xs-12">
                                    <h3>{t('footer_links')}</h3>
                                    <ul className="footer__links">
                                          <li><Link className="link__active" to="/">{t('home')}</Link></li>
                                          <li><Link to="/about">{t('about')}</Link></li>
                                          <li><Link to="/allsections">{t('sections')}</Link></li>
                                          <li><Link to="/offers">{t('offers')}</Link></li>
                                    </ul>
                              </div>
                              <div className="footer__details col-lg-3 col-md-3 col-xs-12">
                                    <h3>{t('others_links')}</h3>
                                    <ul className="footer__links">
                                          <li><Link to="/contact">{t('contact')}</Link></li>
                                          <li><Link to="/conditions">{t('conditions')}</Link></li>
                                          <li><Link to="/favourites">{t('fav')}</Link></li>
                                    </ul>
                              </div>
                              <div className="footer__details col-lg-3 col-md-3 col-xs-12 col-sm-12">
                                    <h3>{t('contact')}</h3>
                                    <ul className="footer__location">
                                          <li>
                                                <a 
                                                      href={`tel:${Contacts.company_phone}`}
                                                >
                                                      <img src={Phone} alt=""/>
                                                      {t('phone_number')}
                                                      <span> : </span>
                                                      <span dir='ltr'>{Contacts.company_phone}</span>
                                                </a>
                                          </li>
                                          <li>
                                                <a href={`mailto:${Contacts.company_email}`}> 
                                                      <img src={Email} alt=""/>
                                                      {t('email')}
                                                      <span> : </span>
                                                      <span>{Contacts.company_email}</span>
                                                </a>
                                          </li>
                                          <li>
                                                <ReactWhatsapp number={`+${Contacts.whatsapp}`} message="Welcome to Zafaf-kw app">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="25px" viewBox="0 0 24 24">
                                                            <g>
                                                                  <path fill="none"  d="M0 0h24v24H0z"/>
                                                                  <path stroke='#fff' strokeWidth=".75" fill='#fff' fillRule="nonzero" d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z"/>
                                                            </g>
                                                      </svg>
                                                      {t('sms')}
                                                      <span> : </span>
                                                      <span dir='ltr'>{Contacts.whatsapp}</span>
                                                </ReactWhatsapp>

                                          </li>
                                    </ul>
                              </div>
                              <div className="footer__details col-lg-3 col-md-12 col-xs-12 col-sm-12">
                                    <h3>{t('social_media-links')}</h3>
                                    <SocialMedia />
                              </div>
                        </div>
                        <div className="copyright">
                              <span>{t('copyright')} &copy; <b onClick={() => window.location.href = 'https://b-inspire.com/'}>{t('B.inspire')}</b></span>
                              <img src={Logo} alt="" />
                        </div>
                  </div>
            </div>
      )
}

export default Footer