import axios from 'axios';
import { t } from 'i18next';
import React from 'react'
import { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Flower from '../../Images/Vector Smart Object.png';
import './SellerLogin.css'

const SellerLogin = () => {
    const [yourName, setYourName] = useState('');
    const [yourPassword, setYourPassword] = useState('');

    const submitSellerLogin = async() => {
        await axios.post(`https://zafaf-kw.com/dashboard/api/v1/seller/loginSeller?email=${yourName}&password=#${yourPassword}`)
        .then((result) => {
            if(result.data.status) {
                
            }
        })
    }

    const [validated, setValidated] = useState(false);
    const handleValidate = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
    }
    return (
        <div className='seller-login'>
            <div className='seller-login-content'>
                <h4 className='seller-login-title'>{t('login-as-merchant')}</h4>
                <Form onSubmit={handleValidate} noValidate validated={validated} className="row">
                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                                required 
                                type="email" 
                                name="" 
                                placeholder={t('e-mail')}
                                value={yourName}
                                onChange={(e) => setYourName(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('name')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                        <Form.Control 
                            required
                            type="password" 
                            placeholder={t('password')} 
                            value={yourPassword}
                            onChange={(e) => setYourPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('password')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                        <button className='login-seller-btn' id="new-verify-phone-btn" type='submit'>
                            <h3>{t('login')}</h3>
                        </button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default SellerLogin;