import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Link, useHistory } from 'react-router-dom';
import ProductCard from '../productCard/productCard';

const RelatedProducts = ({categoryIds}) => {
    const {t} = useTranslation();
    // get product id from params
    const history = useHistory();
        const params = history.location.search
        const blabla = params.split("?")[1].split("&").reduce((acc , curr) => {
            let temp = curr.split("=")
            acc[temp[0]] = temp[1]
            return {...acc}
    })
    const valid_id = blabla.split("=")[1];

    // Fetch products are related
    const lang = localStorage.getItem("lang")
    const [productRelated , setProductRelated] = useState({});
    const FetchProductRelated = async () => {
        const response = await axios(`https://zafaf-kw.com/dashboard/api/v1/products/related-products?lang=${lang}&product_id=${valid_id}`);
        setProductRelated(response.data.products);
        return response.data.products;
    }

    useEffect(() => {
        FetchProductRelated();
    } , [lang]);
    
    return (
        <>
            {
            productRelated?.length > 0 && 
            <div className='invitation'>
                <div className='service-header'>
                    <h4 data-aos="fade-left">{t('Other-similar-products')}</h4>
                    {/* <Link to={`/seemore?id=${categoryIds}`} data-aos="fade-right">{t('see_more')}</Link> */}
                </div>
                <div className='invitation-container' data-aos="fade-up">
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            480: {
                                slidesPerView: 2.5,
                                spaceBetween: 10
                            },
                            540: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3.5,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 5.5,
                                spaceBetween: 10,
                            },
                        }}
                        dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                        className="mySwiper"
                    >
                    {
                        productRelated?.length > 0 ? productRelated?.map((prCard) => (
                            <SwiperSlide key={prCard.id}>
                                <ProductCard product = {{...prCard , id:prCard?.id ?? prCard?.product_id}}/>
                            </SwiperSlide>
                        ))
                        :
                        <div className='no-products'>
                            <span>{t('no-products')}</span>
                        </div>
                    }
                    </Swiper>
                </div>
            </div>
            }
        </>
    )
}

export default RelatedProducts;