import React from 'react'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RWebShare } from 'react-web-share';
import './Share.css'
const Share = () => {
    const { t } = useTranslation();
    return (
        <div className='product_description-share'>
            <RWebShare
                data={{
                        text: "Share with friends",
                        url: window.location.href,
                        title: "Share Zafaf App with friends",
                }}
                onClick={
                        () => { document.documentElement.dir === 'rtl' ? 
                        toast.info("تمت المشاركه بنجاح") 
                        :
                        toast.info("shared successfully!");
                }}
            >
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 42.529 46.922">
                            <g id="Icon_feather-share-2" data-name="Icon feather-share-2" transform="translate(1.5 1.5)">
                                <path id="Path_5169" data-name="Path 5169" d="M35.676,9.588A6.588,6.588,0,1,1,29.088,3,6.588,6.588,0,0,1,35.676,9.588Z" transform="translate(3.853 -3)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_5170" data-name="Path 5170" d="M17.676,20.088A6.588,6.588,0,1,1,11.088,13.5,6.588,6.588,0,0,1,17.676,20.088Z" transform="translate(-4.5 1.873)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_5171" data-name="Path 5171" d="M35.676,30.588A6.588,6.588,0,1,1,29.088,24,6.588,6.588,0,0,1,35.676,30.588Z" transform="translate(3.853 6.745)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_5172" data-name="Path 5172" d="M12.885,20.265l15,8.74" transform="translate(-0.609 5.012)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                <path id="Path_5173" data-name="Path 5173" d="M27.862,9.765l-14.977,8.74" transform="translate(-0.609 0.139)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                            </g>
                    </svg>
                    <span>{t('share')}</span>
                </button>
            </RWebShare>
        </div>
    )
}

export default Share;