import React, { useEffect, useState } from 'react';
import './ExclusiveOffers.css';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import "swiper/modules/lazy/lazy.min.css";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Aos from "aos";
import "aos/dist/aos.css";
import { Lazy , Autoplay} from 'swiper';
import ImageLoading from 'react-image-lazy-progressive-load-with-progress-bar';

const ExclusiveOffers = () => {
      const { t } = useTranslation();
      const [exOffers , setExOffers] = useState([]);
      const fetchExOffers = async () => {
            const response = await axios("https://zafaf-kw.com/dashboard/api/v1/banners?banner_type=middle");
            setExOffers(response.data.banners);
            return response.data.banners;
      }

      useEffect(() => {
            fetchExOffers();
            Aos.init({duration : 1000})
      } , []);

      return (
            <>
                  { exOffers.length > 0 && 
                  <div className="ExclusiveOffers">
                        <div className="ExclusiveOffers-title">
                              <h3>{t('ExclusiveOffers')}</h3>
                        </div>
                        <div className="ExclusiveOffers-container" data-aos="fade-up">
                              <Swiper
                                    loop={true}
                                    autoplay={{
                                          delay: 2000,
                                          disableOnInteraction: false,
                                    }}
                                    autoplayspeed= {2000}
                                    pagination={{
                                          clickable: true,
                                    }}
                                    breakpoints={{
                                          320: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          480: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          540: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          768: {
                                                slidesPerView: 2.5,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          1024: {
                                                slidesPerView: 2.2,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                    }}
                                    dir={'ltr'}
                                    modules={[Lazy , Autoplay]}
                                    className="mySwiper"
                              >
                                    { 
                                          exOffers.length > 0 ? exOffers.map((exOffer , index) => (
                                                <SwiperSlide key={index}>
                                                      <a href={exOffer.url} className='slide-offer' id={'slide-offer-'+JSON.stringify(index)}>
                                                            <img src={exOffer.bannerImage} alt=''/>
                                                            {/* <ImageLoading
                                                                  preview={exOffer.bannerImage}
                                                                  src={exOffer.bannerImage}
                                                                  lazyLoadScreenOffSet={500}
                                                                  blur={10}
                                                                  transitionTime={1}
                                                                  spinnerSize={70}
                                                            /> */}
                                                            <div className='slide-caption'>
                                                                  {document.documentElement.dir === 'rtl' ? <span style={{direction : "rtl"}}>{exOffer.descraption_ar}</span> : <span style={{direction : "ltr"}}>{exOffer.descraption_en}</span>}
                                                            </div>
                                                      </a>
                                                </SwiperSlide>
                                          ))
                                          : 
                                          <div className='no-products'>
                                                <span>{t('no-products')}</span>
                                          </div>
                                    }
                              </Swiper>
                        </div>
                  </div>}
            </>
      )
}

export default ExclusiveOffers;