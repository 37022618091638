import React, { useEffect, useState } from 'react';
import Slider from '../../Components/Slider/OurSlider';
import ExclusiveOffers from '../../Components/ExclusiveOffers/ExclusiveOffers';
import Services from '../../Components/Services/Services';
import Suggestions from '../../Components/Suggestions/Suggestions';
import DownApp from '../../Components/DownApp/DownApp';
import Category from '../Sections/Category';

const Home = ({addToCart}) => {
      useEffect(() => {
            window.scrollTo(0, 0)
      }, [])

      return (
            <div className='home-page'>
                  <Slider />
                  <Category />
                  <ExclusiveOffers />
                  <Services addToCart={addToCart}/>
                  <Suggestions />
                  <DownApp />
            </div>
      )
}

export default Home;