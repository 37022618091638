import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './services.css';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { Link } from 'react-router-dom'
import Aos from 'aos'
import ProductCard from '../productCard/productCard';
import Loading from '../Loading/Loading';

const Services = () => {
      const {t} = useTranslation();
      const [spinner, setSpinner] = useState(true);
      const lang = localStorage.getItem("lang");
      const [allServices , setAllServices] = useState([]);
      const fetchAllServices = async () => {
            await axios(`https://zafaf-kw.com/dashboard/api/v1/categories/productsHome?lang=${lang}`)
            .then((result) => {
                  if (result.data.status) {
                        setSpinner(false)
                        setAllServices(result.data.productsHome);
                  }
            })
      };

      useEffect(() => {
            fetchAllServices();
            Aos.init({duration : 1000});
      } , [lang]);

      return (
            <div className='services-container'>
                  <div className='sevices-title'>
                        <h3>{t('services')}</h3>
                  </div>
                  {spinner && <Loading />}
                  {
                        allServices.length > 0 && allServices.map((services) => (
                              <div className='invitation' key={services?.id}>
                                    <div className='service-header'>
                                          <h4 data-aos="fade-left">{services.name}</h4>
                                          <Link to={`/subcategory?id=${services?.id}`} data-aos="fade-right">{t('see_more')}</Link>
                                    </div>
                                    <div className='invitation-container' data-aos="fade-up">
                                          <Swiper
                                                pagination={{
                                                      clickable: true,
                                                }}
                                                breakpoints={{
                                                      320: {
                                                            slidesPerView: 1.5,
                                                            spaceBetween: 10
                                                      },
                                                      480: {
                                                            slidesPerView: 2.5,
                                                            spaceBetween: 10
                                                      },
                                                      540: {
                                                            slidesPerView: 2.5,
                                                            spaceBetween: 10,
                                                      },
                                                      768: {
                                                            slidesPerView: 3.5,
                                                            spaceBetween: 10,
                                                      },
                                                      1024: {
                                                            slidesPerView: 5.5,
                                                            spaceBetween: 10,
                                                      },
                                                }}
                                                dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                                                className="mySwiper"
                                          >
                                                {
                                                      services.products.length > 0 ? services.products.map((service) => (
                                                            <SwiperSlide key={service?.id}>
                                                                  <ProductCard product={{...service , id:service?.id ?? service?.product_id}} />
                                                            </SwiperSlide>
                                                      ))
                                                      :
                                                      <div className='no-products'>
                                                            <span>{t('no-products')}</span>
                                                      </div>
                                                }
                                          </Swiper>
                                    </div>
                              </div>
                        )) 
                  }
            </div>
      )
}

export default Services;

// className={(document.documentElement.dir === 'rtl') ? 'swiper-ar' : 'swiper-en' }