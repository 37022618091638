import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import server from "../api/api";
import { toast } from "react-toastify";
import swal from "sweetalert";

// initial state
const initialState = {
    products: [],
    loading: false,
    errors: null,
};

const lang = localStorage.getItem("lang")
// thunk effects
export const fetchCart = createAsyncThunk(
    "cart/GetShoppingCart",
    async (user_id) => {
        try {
            const response = await server.get(`/cart?lang=${lang}`, {
                params: { user_id: parseInt(user_id) },
            });
            if (!response.data.status) {
                throw response.data;
            }
            // toast.success(`${response.data.message}`)
            return response.data;
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه ") : toast.error("Try again")
            throw error;
        }
    }
);

export const addProduct = createAsyncThunk(
    "cart/addProductToShoppingCart",
    async ({ user_id, product_id, quantity, callBack }) => {
        try {
            if(localStorage.getItem('user_id')) {
                const response = await server.post(`/cart/add?lang=${lang}&user_id=${user_id}&product_id=${product_id}&quantity=${quantity}`);
                if (response.data.status) {
                    document.documentElement.dir === 'rtl' ? toast.success("تم إضافه المنتج إلى السله") : toast.success("Product added successfully");
                    callBack && callBack()
                    return response.data;
                } else if(!response.data.status){
                    document.documentElement.dir === 'rtl' ? toast.error("هذا المنتج مضاف إلى السله بالفعل") : toast.error("The product is already exist");
                }
                
            } else {
                document.documentElement.dir === 'rtl' ? 
                swal({
                    title: " قم بالتسجيل أولا",
                    icon: "warning",
                    button: "OK!",
                })
                : 
                swal({
                    title: "please first login",
                    icon: "warning",
                    button: "OK!",
                });
            }
            // if (!response.data.status) {
            //     throw response.data;
            // }
            // toast.success(`${response.data.message}`)

            
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه") : toast.error("Try again");
            throw error;
        }
    }
);

export const deleteProduct = createAsyncThunk(
    "cart/deleteProductFromShoppingCart",
    async ({ user_id, order_id, callBack }) => {
        try {
            const response = await server.delete("/cart/remove", {
                params: { user_id, order_id }
            });
            if (!response.data.status) {
                throw response.data;
            }
            document.documentElement.dir === 'rtl' ? toast.success("تم حذف المنتج من السله") : toast.success(response.data.message) 
            callBack && callBack()
            return response.data

        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه ") : toast.error("Try again")
            throw error;
        }
    }
);
export const adjustProduct = createAsyncThunk(
    "cart/adjustProductAtShoppingCart",
    async ({ user_id, order_id, quantity, callBack }) => {
        try {
            const response = await server.put(`/cart/update?quantity=${quantity}&user_id=${user_id}&order_id=${order_id}`);
            if (!response.data.status) {
                throw response.data;
            }
            // toast.success(`${response.data.message}`)
            document.documentElement.dir === 'rtl' ? 
                toast.success("تم تحديث المنتج بنجاح") : toast.success(`${response.data.message}`);
            callBack && callBack()
            return response.data
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه ") : toast.error("Try again")
            throw error;
        }
    }
);

// cart slice

const shoppingCartSlice = createSlice({
    name: "shoppingCart",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCart.pending]: (state) => {
            state.loading = true;
        },
        [fetchCart.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.products = [...payload?.cart];
            state.errors = null;
        },
        [fetchCart.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
        [addProduct.pending]: (state) => {
            state.loading = true;
        },
        [addProduct.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.errors = null;
        },
        [addProduct.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
        [adjustProduct.pending]: (state) => {
            state.loading = true;
        },
        [adjustProduct.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.errors = null;
        },
        [adjustProduct.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
        [deleteProduct.pending]: (state) => {
            state.loading = true;
        },
        [deleteProduct.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.errors = null;
        },
        [deleteProduct.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
    },


});

export const cartActions = shoppingCartSlice.actions;

export default shoppingCartSlice.reducer;
