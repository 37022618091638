import { initializeApp } from 'firebase/app';
import {getAuth} from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyB0SHZwgCbcq65XtwU4myJ0GIQC28KS1Kw",
    authDomain: "zafaf-f07cd.firebaseapp.com",
    projectId: "zafaf-f07cd",
    storageBucket: "zafaf-f07cd.appspot.com",
    messagingSenderId: "651806530439",
    appId: "1:651806530439:web:e795bdbf48340d362c1122",
    measurementId: "G-0TY41X11GN"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);