import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import Modal from 'react-bootstrap/Modal';
import { RatingStar } from 'rating-star';
import { Button, Form } from 'react-bootstrap';
import ReviewImg from '../../Images/Logo-load.png'
import './paymentCompleted.css'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Cart from '../Cart/Cart';

function ModalReview(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const lang = localStorage.getItem('lang')
    const userID = localStorage.getItem("user_id");
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const onRatingChange = score => {
        setRating(score);
    };
    const handleAddReview = async () => {
        await axios.post(`https://zafaf-kw.com/dashboard/api/v1/products/reviews/submit?user_id=${userID}&comment=${comment}&rating=${rating}&lang=${lang}`)
        .then((result) => {
            if (result.data.status) {
                document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success(result.data.message)
            } else if (!result.data.status) {
                document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success(result.data.message)
            }
        })
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                    <Modal.Title className="mb-2" id="contained-modal-title-vcenter">
                    {t('review_service')}
                    </Modal.Title>
                    <img src={ReviewImg} alt=""/>
            </Modal.Header>
            <Modal.Body>
                    <h6>{t('plz_review_service')}</h6>
                    <RatingStar
                        id="clickable"
                        clickable
                        rating={rating}
                        numberOfStar={5}
                        maxScore={5}
                        onRatingChange={onRatingChange}
                    />
                    <Form.Control
                        as="textarea"
                        placeholder={t('write-comment')}
                        style={{ height: '120px' , width: "100%"}}
                        className="mb-2 contact-area"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    />
            </Modal.Body>
            <Modal.Footer>
                    <Button className="modal-btn" onClick = {
                        () => {
                            handleAddReview()
                            props.onHide()
                            history.push("/")
                        }}
                    >{t('send')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

const PaymentCompleted = () => {
    const history = useHistory();
    const params = history.location.search
    const paymentStatus = params && params?.split("?")[1].split("&").reduce((acc , curr) => {
        let temp = curr.split("=")
        acc[temp[0]] = temp[1]
        return {...acc}
    })
    const paymentStatusFull = paymentStatus.split("=")[1];

    const [modalShow, setModalShow] = useState(true);

    useEffect(() => {
        if (paymentStatusFull === 'success') {
            document.documentElement.dir === 'rtl' ? toast.success("عمليه الدفع تمت بنجاح") : toast.success("payment process successful")
            setModalShow(true)
        } else if (paymentStatusFull === 'Failure') {
            document.documentElement.dir === 'rtl' ? toast.error("حدث مشكله في عمليه الدفع!") : toast.error("Failed Payment");
            window.location.replace('https://zafaf-kw.com/cart')
        }
    } , [paymentStatusFull])

    return (
        <div className='add-review'>
            <ModalReview
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }}
            />
        </div>
    )
}

export default PaymentCompleted