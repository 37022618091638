import React, { useEffect, useState } from 'react';
import './PageLoading.css';
import FLW from '../../Images/Vector Smart Object.png'
const PageLoading = () => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 3500);
    }, []);

    return (
        <>
            {
                isLoading && (
                    <div className="loader-container" data-aos="fade-out">
                        <div className="loader-container-proccess">
                            <div className='loader-logo'>
                                <svg
                                    viewBox="0 0 3779.5332 3779.5332"
                                    id="svg2"
                                    version="1.1"
                                    width="300"
                                    height="400"
                                >
                                    <defs id="defs6">
                                        <linearGradient
                                            id="linearGradient46"
                                            spreadMethod="pad"
                                            gradientTransform="matrix(247.10846,0,0,-247.10846,1627.9886,2361.1565)"
                                            gradientUnits="userSpaceOnUse"
                                            y2="0"
                                            x2="1"
                                            y1="0"
                                            x1="0"
                                        >
                                            <stop
                                                id="stop32"
                                                offset="0"
                                                style={{stopOpacity:1,stopColor:'#d7b140'}}
                                            />
                                            <stop
                                                id="stop34"
                                                offset="0.227907"
                                                style={{stopOpacity:1,stopColor:'#a17b1e'}}
                                            />
                                            <stop
                                                id="stop36"
                                                offset="0.413953"
                                                style={{stopOpacity:1,stopColor:'#c09c3a'}}
                                            />
                                            <stop
                                                id="stop38"
                                                offset="0.586047"
                                                style={{stopOpacity:1,stopColor:'#aa7e1f'}}
                                            />
                                            <stop
                                                id="stop40"
                                                offset="0.772093"
                                                style={{stopOpacity:1,stopColor:'#85541d'}}
                                            />
                                            <stop
                                                id="stop42"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#cda43e'}}
                                            />
                                            <stop
                                                id="stop44"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#cda43e'}}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="linearGradient76"
                                            spreadMethod="pad"
                                            gradientTransform="matrix(842.77942,0,0,-842.77942,1450.2692,1636.7592)"
                                            gradientUnits="userSpaceOnUse"
                                            y2="0"
                                            x2="1"
                                            y1="0"
                                            x1="0"
                                        >
                                            <stop
                                                id="stop62"
                                                offset="0"
                                                style={{stopOpacity:1,stopColor:'#d7b140'}}
                                            />
                                            <stop
                                                id="stop64"
                                                offset="0.227907"
                                                style={{stopOpacity:1,stopColor:'#a17b1e'}}
                                            />
                                            <stop
                                                id="stop66"
                                                offset="0.413953"
                                                style={{stopOpacity:1,stopColor:'#c09c3a'}}
                                            />
                                            <stop
                                                id="stop68"
                                                offset="0.586047"
                                                style={{stopOpacity:1,stopColor:'#aa7e1f'}}
                                            />
                                            <stop
                                                id="stop70"
                                                offset="0.772093"
                                                style={{stopOpacity:1,stopColor:'#85541d'}}
                                            />
                                            <stop
                                                id="stop72"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#cda43e'}}
                                            />
                                            <stop
                                                id="stop74"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#cda43e'}}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="linearGradient110"
                                            spreadMethod="pad"
                                            gradientTransform="matrix(-247.10846,0,0,-247.10846,1206.657,2361.1565)"
                                            gradientUnits="userSpaceOnUse"
                                            y2="0"
                                            x2="1"
                                            y1="0"
                                            x1="0"
                                        >
                                            <stop
                                                id="stop92"
                                                offset="0"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop94"
                                                offset="0.01161424"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop96"
                                                offset="0.10807"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop98"
                                                offset="0.27276511"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop100"
                                                offset="0.41019"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop102"
                                                offset="0.4104114"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop104"
                                                offset="0.653713"
                                                style={{stopOpacity:1,stopColor:'#4a494b'}}
                                            />
                                            <stop
                                                id="stop106"
                                                offset="0.85258863"
                                                style={{stopOpacity:1,stopColor:'#4a494b'}}
                                            />
                                            <stop
                                                id="stop108"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#babbbc'}}
                                            />
                                        </linearGradient>
                                        <linearGradient
                                            id="linearGradient144"
                                            spreadMethod="pad"
                                            gradientTransform="matrix(-842.77942,0,0,-842.77942,1384.3766,1636.7592)"
                                            gradientUnits="userSpaceOnUse"
                                            y2="0"
                                            x2="1"
                                            y1="0"
                                            x1="0"
                                        >
                                            <stop
                                                id="stop126"
                                                offset="0"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop128"
                                                offset="0.01161424"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop130"
                                                offset="0.10807"
                                                style={{stopOpacity:1,stopColor:'#a4a5a8'}}
                                            />
                                            <stop
                                                id="stop132"
                                                offset="0.27276511"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop134"
                                                offset="0.41019"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop136"
                                                offset="0.4104114"
                                                style={{stopOpacity:1,stopColor:'#454346'}}
                                            />
                                            <stop
                                                id="stop138"
                                                offset="0.653713"
                                                style={{stopOpacity:1,stopColor:'#4a494b'}}
                                            />
                                            <stop
                                                id="stop140"
                                                offset="0.85258863"
                                                style={{stopOpacity:1,stopColor:'#4a494b'}}
                                            />
                                            <stop
                                                id="stop142"
                                                offset="1"
                                                style={{stopOpacity:1,stopColor:'#babbbc'}}
                                            />
                                        </linearGradient>
                                    </defs>
                                    <g transform="matrix(1.3333333,0,0,-1.3333333,0,3779.5333)" id="g10" height="300px">
                                        <g data-aos="fade-down-left" data-aos-delay="800" id="g20"><g id="g22"><g id="g28"><g id="g30"><path id="path48" style={{ fill: 'url(#linearGradient46)', stroke: 'none' }} d="m 1627.988,2361.156 c 0,-68.237 55.318,-123.553 123.555,-123.553 v 0 c 68.237,0 123.554,55.316 123.554,123.553 v 0 c 0,68.237 -55.317,123.555 -123.554,123.555 v 0 c -68.237,0 -123.555,-55.318 -123.555,-123.555"/></g></g></g></g>
                                        <g data-aos="zoom-out-left" data-aos-duration="600" data-aos-delay="1500" id="g50"><g id="g52"><g id="g58"><g id="g60"> <path id="path78" height="300px" style={{ fill: 'url(#linearGradient76)', stroke: 'none' }} d="m 1601.372,2158.221 c -54.316,-28.141 -102.118,-70.665 -127.846,-126.997 v 0 c -30.589,-66.979 -33.545,-167.722 6.48,-231.625 v 0 c 27.161,-43.364 65.627,-62.318 107.54,-52.736 v 0 c -84.363,74.753 -77.005,151.007 -77.005,151.007 v 0 c 31.067,-67.77 74.661,-109.084 115.881,-134.287 v 0 c 0.195,0.124 0.39,0.241 0.586,0.367 v 0 c 0,0 -150.059,128.049 -18.348,270.725 v 0 c 57.706,62.509 150.763,78.633 224.79,36.711 v 0 c 44.659,-25.29 84.809,-72.589 95.951,-159.783 v 0 c 8.277,-64.773 -2.953,-130.487 -30.946,-189.483 v 0 c -33.255,-70.086 -97.417,-173.966 -209.764,-253.035 v 0 c -77.861,-54.797 -145.138,-123.988 -195.235,-204.953 v 0 c -19.924,-32.2 -34.155,-62.243 -34.155,-62.243 v 0 c 0,0 321.041,219.457 522.29,195.499 v 0 c 0,0 -311.457,-148.541 -431.248,-277.916 v 0 c 0,0 618.122,-253.957 742.706,517.498 v 0 c 0,0 -76.668,-143.749 -416.874,-114.999 v 0 c 0,0 322.241,377.128 80.875,592.569 v 0 c -58.886,52.56 -133.515,79.531 -208.989,79.533 v 0 c -49.965,0 -100.294,-11.816 -146.689,-35.852"/></g></g></g></g>
                                        <g data-aos="fade-down-right" data-aos-delay="800" id="g80"><g id="g82"><g id="g88"><g id="g90"><path id="path112" style={{ fill: 'url(#linearGradient110)', stroke: 'none' }} d="m 959.549,2361.156 c 0,-68.237 55.317,-123.553 123.553,-123.553 v 0 c 68.237,0 123.555,55.316 123.555,123.553 v 0 c 0,68.237 -55.318,123.555 -123.555,123.555 v 0 c -68.236,0 -123.553,-55.318 -123.553,-123.555"/></g></g></g></g>
                                        <g data-aos="zoom-in-left" data-aos-duration="800" data-aos-delay="1300" id="g114"><g id="g116"><g id="g122"><g id="g124"><path id="path146" height="300px" style={{ fill: 'url(#linearGradient144)', stroke: 'none' }} d="M 877.596,2114.54 C 636.23,1899.099 958.47,1521.971 958.47,1521.971 v 0 C 618.264,1493.221 541.597,1636.97 541.597,1636.97 v 0 c 124.583,-771.455 742.707,-517.498 742.707,-517.498 v 0 c -119.792,129.375 -431.249,277.916 -431.249,277.916 v 0 c 201.249,23.958 522.289,-195.499 522.289,-195.499 v 0 c 0,0 -14.231,30.043 -34.155,62.243 v 0 c -50.096,80.965 -117.374,150.156 -195.234,204.953 v 0 c -112.347,79.069 -176.508,182.949 -209.765,253.035 v 0 c -27.993,58.996 -39.223,124.71 -30.946,189.483 v 0 c 11.143,87.194 51.292,134.493 95.951,159.783 v 0 c 74.027,41.922 167.085,25.798 224.79,-36.711 v 0 c 131.712,-142.676 -18.348,-270.725 -18.348,-270.725 v 0 c 0.195,-0.126 0.392,-0.243 0.587,-0.367 v 0 c 41.221,25.203 84.813,66.517 115.88,134.287 v 0 c 0,0 7.359,-76.254 -77.004,-151.007 v 0 c 41.913,-9.582 80.379,9.372 107.54,52.736 v 0 c 40.024,63.903 37.07,164.646 6.48,231.625 v 0 c -25.729,56.332 -73.531,98.856 -127.846,126.997 v 0 c -46.396,24.036 -96.725,35.853 -146.689,35.852 v 0 c -75.474,0 -150.104,-26.973 -208.989,-79.533"/></g></g></g></g>
                                    </g>
                                </svg>
                            </div>
                            <h1 data-aos="zoom-out" data-aos-duration="1500" data-aos-delay="1700" >Zafaf</h1>
                            <span data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2500" >for marriage</span>
                        </div>
                        <div className="loader-container-img">
                            <img src={FLW} alt="" />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default PageLoading