import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { addProduct, fetchCart } from '../../store/cartSlice';
import { addProductToWishList, deleteProductFromWishList, fetchWishlist } from '../../store/wishListSlice';

const AddToFavourite = (props) => {
    const {t} = useTranslation();
    const { wishList } = useSelector(state => state.wishList)
    const dispatch = useDispatch();
    const inWishList = useMemo(
        () => wishList.length > 0 ?
        wishList.filter(item => item?.id === parseInt(props.valid_id) || item?.product_id === parseInt(props.valid_id)).length > 0 : false,
        [props.valid_id, wishList]
    )
    const userID = localStorage.getItem("user_id");
    const lang = localStorage.getItem("lang")

    const addToWish = () => {
        if (inWishList) {
            dispatch(deleteProductFromWishList({
                user_id: parseInt(userID), product_id: props.valid_id, callBack: () => {
                    dispatch(fetchWishlist(parseInt(userID)));
                    document.documentElement.dir === 'rtl' ? toast.error("تم حذف المنتج من المفضله") : toast.error("Product removed successfully")
                }
            }))
            return
        }
        dispatch(addProductToWishList({
            user_id: parseInt(userID), product_id: props.valid_id, callBack: () => {
                dispatch(fetchWishlist(parseInt(userID)));
                document.documentElement.dir === 'rtl' ? toast.success("تم إضافه المنتج إلى المفضله") : toast.success("Product added successfully")
            }
        }))
    }

    return (
        <div className='product_description-favs' onClick={addToWish}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 46.036 40.536">
                    <path className={inWishList ? 'navbar-favourites-active' : ''} id="Icon_feather-heart" data-name="Icon feather-heart" d="M42.04,7.816a11.324,11.324,0,0,0-16.018,0L23.84,10,21.658,7.816A11.326,11.326,0,1,0,5.64,23.833l2.182,2.182L23.84,42.033,39.858,26.016l2.182-2.182a11.324,11.324,0,0,0,0-16.018Z" transform="translate(-0.823 -2.997)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
            </svg>
            <span>{t('fav')}</span>
        </div>
    )
}

export default AddToFavourite