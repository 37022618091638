import axios from 'axios';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const Logout = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const userID = localStorage.getItem("user_id");
    const handleLogout = async () => {
        document.documentElement.dir === 'rtl' ? toast.info("تم تسجيل الخروج بنجاح") : toast.info("You're logged out successfuly")
        localStorage.removeItem('user_id');
        history.push('/')
        await axios
            .post(`https://zifaf.site/api/v1/auth/logout?user_id=${userID}`)
            .then((result) => {
                if (result.data.status) {
                    document.documentElement.dir === 'rtl' ? toast.info("تم تسجيل الخروج بنجاح") : toast.info("You're logged out successfuly")
                    localStorage.removeItem('user_id');
                    history.push('/')
                }
            });
    };
    
    return (
        <button className='logout' onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none">
                <g id="Iconly/Curved/Logout">
                    <g id="Logout">
                            <path id="Stroke 1" d="M21.791 12.1208H9.75" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Stroke 3" d="M18.8643 9.20483L21.7923 12.1208L18.8643 15.0368" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path id="Stroke 4" d="M16.3597 7.63C16.0297 4.05 14.6897 2.75 9.35974 2.75C2.25874 2.75 2.25874 5.06 2.25874 12C2.25874 18.94 2.25874 21.25 9.35974 21.25C14.6897 21.25 16.0297 19.95 16.3597 16.37" stroke="#130F26" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                </g>
            </svg>
            <span>{t('logout')}</span>
        </button>
    )
}

export default Logout;