import React, { useEffect, useState } from 'react';
import { ToastContainer } from "react-toastify";
import './App.css';
import './i18n';
import Aos from 'aos';
import { useDispatch } from 'react-redux';
import { fetchCart } from './store/cartSlice';
import { fetchWishlist } from './store/wishListSlice';
import 'react-toastify/dist/ReactToastify.css';
import "react-toastify/dist/ReactToastify.minimal.css";
import Routes from './Routes/Routes';
import { useTranslation } from 'react-i18next';

const App = () => {
    const {i18n} = useTranslation();
    useEffect(() => {
        let lang = localStorage.getItem("lang")
        let dir = localStorage.getItem("dir")
        if(lang == null || lang.length<=0){
            localStorage.setItem("lang","ar")
            localStorage.setItem("dir","rtl")
            lang ="ar"
            dir ="rtl"
        }
        document.documentElement.lang=lang
        document.documentElement.dir=dir        
        i18n.changeLanguage(lang);
    },[i18n])
    
    const dispatch = useDispatch();
    const user_id = localStorage.getItem("user_id"); 
    useEffect(() => {
        if (user_id) {
            dispatch(fetchCart(user_id))
            dispatch(fetchWishlist(user_id))
        }
    }, [user_id, dispatch]);

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [isOnline , setIsOnline] = useState(navigator.onLine)
    return (
        <>
            {/* All routes here */}
            <Routes />

            {/* Handle Notifications */}
            <ToastContainer
                limit={3}
                rtl 
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>

    )
}

export default App;