import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrClose } from 'react-icons/gr'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './book.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

const Book = (props) => {
    const {t} = useTranslation();
    const [startDate , setStartDate] = useState();
    const [startTime , setStartTime] = useState();
    const [yourDate, setDate] = useState("");
    const [yourTime, setTime] = useState("");

    const handleBooked = async () => {
        if(startDate && startTime) {
            await axios
                .post(
                    `https://zafaf-kw.com/dashboard/api/v1/book?user_id=${props.userID}&product_id=${props.valid_id}&time=${yourTime}&date=${yourDate}&returnTypeMessage={'web'}`
                )
                .then((result) => {
                    if (result.data.status) {
                        // document.documentElement.dir === 'rtl' ?
                        // toast.success("تم الحجز بنجاح") 
                        // :
                        // toast.success("Booked successfully")
                        props.setIsBooked(!props.isBooked)
                        window.location.replace(result.data.url);
                    } else if(!result.data.status) {
                        // document.documentElement.dir === 'rtl' ? 
                        // toast.error("لم يتم الحجز بنجاح") 
                        // : 
                        // toast.error("No Booked successfully")
                    }
                });
        } else {
            document.documentElement.dir === 'rtl' ? swal("أدخل الوقت والتاريخ") : swal("ُEnter Date and Time")
        }
    };
    useEffect(() => {
        startDate && setDate(startDate.toLocaleDateString());
        startDate && setTime(startDate.toLocaleTimeString());
    } , [startDate , startTime])

    return (
        <div className='book'>
            <div className='book-content'>
                <span className='close-sidebar' onClick={() => props.setIsBooked(!props.isBooked)}><GrClose></GrClose></span>
                <h2 className='book-content-title'>{t('book-now')}</h2>
                <div className='book-content-form'>
                    <div className='datepicker-wrapper'>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="MMMM d, yyyy"
                            isClearable
                            placeholderText={document.documentElement.dir === 'rtl' ? "أدخل التاريخ" : "Enter Date"}
                        />
                        <span className={document.documentElement.dir === 'rtl' ? 'date-icon-ar' : 'date-icon-en'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"><path d="m22 2.25h-3.25v-1.5c-.014-.404-.344-.726-.75-.726s-.736.322-.75.725v.001 1.5h-4.5v-1.5c-.014-.404-.344-.726-.75-.726s-.736.322-.75.725v.001 1.5h-4.5v-1.5c-.014-.404-.344-.726-.75-.726s-.736.322-.75.725v.001 1.5h-3.25c-1.104 0-2 .895-2 1.999v17.75c0 1.105.895 2 2 2h20c1.105 0 2-.895 2-2v-17.75c0-1.104-.896-1.999-2-1.999zm.5 19.75c0 .276-.224.499-.499.5h-20.001c-.276 0-.5-.224-.5-.5v-17.75c.001-.276.224-.499.5-.499h3.25v1.5c.014.404.344.726.75.726s.736-.322.75-.725v-.001-1.5h4.5v1.5c.014.404.344.726.75.726s.736-.322.75-.725v-.001-1.5h4.5v1.5c.014.404.344.726.75.726s.736-.322.75-.725v-.001-1.5h3.25c.276 0 .499.224.499.499z"/><path d="m5.25 9h3v2.25h-3z"/><path d="m5.25 12.75h3v2.25h-3z"/><path d="m5.25 16.5h3v2.25h-3z"/><path d="m10.5 16.5h3v2.25h-3z"/><path d="m10.5 12.75h3v2.25h-3z"/><path d="m10.5 9h3v2.25h-3z"/><path d="m15.75 16.5h3v2.25h-3z"/><path d="m15.75 12.75h3v2.25h-3z"/><path d="m15.75 9h3v2.25h-3z"/></svg>
                        </span>
                    </div>
                    <div className='datepicker-wrapper'>
                        <DatePicker
                            selected={startTime}
                            onChange={(time) => setStartTime(time)}
                            isClearable
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            placeholderText={document.documentElement.dir === 'rtl' ? "أدخل الوقت" : "Enter Time"}
                        />
                        <span className={document.documentElement.dir === 'rtl' ? 'time-icon-ar' : 'time-icon-en'}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 459.475 459.475" style={{'enable-background':'new 0 0 459.475 459.475'}}>
                                <g>
                                    <path d="M229.737,0C103.063,0,0.001,103.063,0.001,229.737c0,126.676,103.062,229.737,229.736,229.737   c126.677,0,229.736-103.062,229.736-229.737C459.474,103.063,356.414,0,229.737,0z M229.737,431.456   c-111.231,0-201.719-90.484-201.719-201.719c0-111.232,90.488-201.72,201.719-201.72c111.236,0,201.72,90.488,201.72,201.72   C431.457,340.972,340.974,431.456,229.737,431.456z M171.037,372.682l10.529,3.824l-13.571,37.407l-10.528-3.819L171.037,372.682z    M125.285,343.204l8.591,7.195l-25.552,30.509l-8.591-7.192L125.285,343.204z M92.328,299.941l5.608,9.691l-34.443,19.935   l-5.609-9.695L92.328,299.941z M78.134,259.023l-39.188,6.911l-1.945-11.032l39.189-6.911L78.134,259.023z M76.817,204.692   l-39.19-6.917l1.945-11.031l39.19,6.913L76.817,204.692z M94.182,153.167l-34.458-19.896l5.603-9.702l34.459,19.899L94.182,153.167   z M111.122,73.03l25.578,30.482l-8.577,7.198l-25.582-30.482L111.122,73.03z M171.442,41.174L185.05,78.57l-10.521,3.833   l-13.613-37.396L171.442,41.174z M239.023,71.683h-11.201v-39.8h11.201V71.683z M295.183,42.447l10.53,3.831l-13.605,37.398   l-10.528-3.833L295.183,42.447z M337.85,113.062l-8.586-7.204l25.587-30.481l8.578,7.204L337.85,113.062z M370.795,156.379   l-5.602-9.708l34.468-19.896l5.602,9.705L370.795,156.379z M385.022,197.299l39.189-6.908l1.95,11.04l-39.192,6.908   L385.022,197.299z M386.348,251.614l39.188,6.9l-1.946,11.048l-39.192-6.918L386.348,251.614z M368.999,303.148l34.463,19.896   l-5.604,9.707l-34.462-19.901L368.999,303.148z M335.04,345.633l25.587,30.479l-8.591,7.201l-25.574-30.484L335.04,345.633z    M288.638,373.901l13.615,37.396l-10.528,3.835l-13.615-37.396L288.638,373.901z M224.134,384.638h11.207v39.806h-11.207V384.638z    M255.084,229.742c0,9.406-5.186,17.516-12.793,21.893v33.128l-14.464,27.678l-14.461-27.678v-35.842   c-5.442-4.641-8.967-11.469-8.967-19.184c0-8.496,4.214-15.975,10.635-20.57v-67.919l14.463-27.686l14.462,27.686v67.52   C250.663,213.332,255.072,221.011,255.084,229.742z"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <button 
                        className='book-content-form-btn' 
                        type='submit'
                        onClick={handleBooked}
                    >{t('book-now')}</button>
                </div>
            </div>
        </div>
    )
}

export default Book