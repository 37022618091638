import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SliderImage from 'react-zoom-slider';
import { addProductToWishList, deleteProductFromWishList, fetchWishlist } from '../../store/wishListSlice';
import Maps from '../Maps/Maps';
import Book from '../BookNow/Book';
import ImgFullscreen from '../ImgFullscreen/ImgFullscreen';
import { RatingStar } from 'rating-star';
import './MaritalId.css'
import Share from '../Share/Share';
import AddToFavourite from '../AddToFavourite/AddToFavourite';
import IconDesc from '../Icons/IconDesc';
import IconReview from '../Icons/IconReview';
import IconLocation from '../Icons/IconLocation';

const MaritalID = () => {
    const { t } = useTranslation();
    const [isBooked, setIsBooked] = useState(false)
    const [isImgFull, setIsImgFull] = useState(false)
    const history = useHistory();
    const params = history.location.search
    const editParam = params.split("?")[1].split("&").reduce((acc, curr) => {
        let temp = curr.split("=")
        acc[temp[0]] = temp[1]
        return { ...acc }
    })
    const valid_id = editParam.split("=")[1];
    const { wishList } = useSelector(state => state.wishList)
    const dispatch = useDispatch();
    const inWishList = useMemo(() => wishList.length > 0 ? wishList.filter(item => item?.id === parseInt(valid_id) || item?.product_id === parseInt(valid_id)).length > 0 : false, [valid_id, wishList])
    const userID = localStorage.getItem("user_id");
    const lang = localStorage.getItem("lang")
    const [DoctorDetails, setProductDetails] = useState({});
    const [getFirstImg, setGetFirstImg] = useState('');
    const imgUrl = "https://zafaf-kw.com/dashboard/storage/app/public/martiel/";
    const FetchProductDesc = async () => {
        const response = await axios(`https://zafaf-kw.com/dashboard/api/v1/products/martielId?lang=${lang}&martiel_id=${valid_id}`);
        setProductDetails(response.data.martiel);
        return response.data.martiel;
    }
    useEffect(() => { FetchProductDesc() }, [lang]);
    // needed handlers add to wishlist
    const addToWish = () => {
        if (inWishList) {
            dispatch(deleteProductFromWishList({
                user_id: parseInt(userID), product_id: valid_id, callBack: () => {
                        dispatch(fetchWishlist(parseInt(userID)));
                        document.documentElement.dir === 'rtl' ? toast.error("تم حذف المنتج من المفضله") : toast.error("Product removed successfully")
                }
            }))
            return
        }
        dispatch(addProductToWishList({
            user_id: parseInt(userID), product_id: valid_id, callBack: () => {
                dispatch(fetchWishlist(parseInt(userID)));
                document.documentElement.dir === 'rtl' ? toast.success("تم إضافه المنتج إلى المفضله") : toast.success("Product added successfully")
            }
        }))
    }
    const allImages = useMemo(() => DoctorDetails.images && DoctorDetails.images.map((img) => (
        { image: imgUrl + img }
    )), [DoctorDetails.images]);
    useMemo(() => DoctorDetails.images && DoctorDetails.images.map((img , index) => (
        setGetFirstImg(imgUrl+img)
    )), [DoctorDetails.images]);
    const getImgByRef = useRef();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <div className='marital-description'>
                <div className='product_description-carousel'>
                    <div className='product_description-carousel-header'>
                        <div>
                                <h4>{DoctorDetails.name}</h4>
                                <Link className='product_description-seller'>{DoctorDetails.doctor_name}</Link>
                        </div>
                        <div>
                            <Share />
                            {/* <div className='product_description-favs' onClick={addToWish}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 46.036 40.536">
                                        <path className={inWishList ? 'navbar-favourites-active' : ''} id="Icon_feather-heart" data-name="Icon feather-heart" d="M42.04,7.816a11.324,11.324,0,0,0-16.018,0L23.84,10,21.658,7.816A11.326,11.326,0,1,0,5.64,23.833l2.182,2.182L23.84,42.033,39.858,26.016l2.182-2.182a11.324,11.324,0,0,0,0-16.018Z" transform="translate(-0.823 -2.997)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                                </svg>
                                <span>{t('fav')}</span>
                            </div> */}
                            <AddToFavourite valid_id={valid_id}/>
                        </div>
                    </div>
                    <div className='product_description-carousel-content' ref={getImgByRef}>
                        {
                            allImages && allImages.length > 0 &&
                            <SliderImage
                                data={allImages}
                                width="700px"
                                showDescription={true}
                                direction="right"
                            />
                        }
                        <span className='product_description-fullscreen' onClick={() => setIsImgFull(!isImgFull)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 384.97 384.97">
                                <g id="Fullscreen">
                                        <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223    c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03    l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"/>
                                        <path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03    L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242    c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"/>
                                        <path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092    c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092    c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"/>
                                        <path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03    s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012    c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"/>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div className='product_description-carousel-footer'>
                        <div>
                            <h4>{DoctorDetails.name}</h4>
                            {(DoctorDetails.reviewCount > 0 && DoctorDetails.rating > 0) && 
                                <span className='product_description-review'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 53.385 51.096">
                                            <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M25.27,1.777,18.754,14.988,4.176,17.113a3.2,3.2,0,0,0-1.766,5.448L12.956,32.839,10.462,47.358a3.191,3.191,0,0,0,4.63,3.363l13.042-6.855,13.042,6.855a3.194,3.194,0,0,0,4.63-3.363L43.311,32.839,53.858,22.562a3.2,3.2,0,0,0-1.766-5.448L37.513,14.988,31,1.777A3.2,3.2,0,0,0,25.27,1.777Z" transform="translate(-1.441 0.001)" fill="#ffbe44" />
                                    </svg>
                                <h6>{DoctorDetails.reviewCount} ( {t('review')} {DoctorDetails.rating} )</h6>
                            </span>}
                        </div>
                        <div>
                            {
                            DoctorDetails.price && 
                            <div className='marital-salary'>
                                <span>{DoctorDetails.price} {t('currency')}</span>
                            </div>
                            }
                            <div className='product_description-booking product_description-booking-marital'>
                                <button className='product-booking-btn' onClick={() => setIsBooked(!isBooked)}>
                                    {t('book-now')}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                        <path d="M8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12H8Z" fill="#fff"/>
                                        <path d="M7 17C7 16.4477 7.44772 16 8 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H8C7.44772 18 7 17.5523 7 17Z" fill="#fff"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V4.10002C3.71776 4.56329 2 6.58104 2 9V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V9C22 6.58104 20.2822 4.56329 18 4.10002V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3ZM20 10H4V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V10ZM4.17071 8C4.58254 6.83481 5.69378 6 7 6H17C18.3062 6 19.4175 6.83481 19.8293 8H4.17071Z" fill="#fff"/>
                                    </svg>
                                </button>
                                <a className='product-call-btn' href={`tel:+965${DoctorDetails.phone}`}>
                                    {t('call-now')}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.518 26.568"><path id="Icon_feather-phone" data-name="Icon feather-phone" d="M28.183,21.767V25.54a2.516,2.516,0,0,1-2.742,2.516,24.892,24.892,0,0,1-10.855-3.861A24.527,24.527,0,0,1,7.04,16.647,24.892,24.892,0,0,1,3.178,5.742,2.516,2.516,0,0,1,5.681,3H9.455A2.516,2.516,0,0,1,11.97,5.163a16.15,16.15,0,0,0,.88,3.534,2.516,2.516,0,0,1-.566,2.654l-1.6,1.6A20.125,20.125,0,0,0,18.234,20.5l1.6-1.6a2.516,2.516,0,0,1,2.654-.566,16.15,16.15,0,0,0,3.534.88,2.516,2.516,0,0,1,2.163,2.553Z" transform="translate(-2.417 -2.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='product_description-details'>
                    <Tabs
                        defaultActiveKey="desc"
                        id="justify-tab-example"
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="desc" title={<div className='product_description-desc-tab'>{t('desc')} <IconDesc /></div>} >
                            <div>
                                <p>{DoctorDetails.desc}</p>
                            </div>
                        </Tab>
                        <Tab eventKey="reviewing" title={<div className='product_description-review-tab'>{t('reviewing')} <IconReview /></div>}>
                            <div className="reviewing-container">
                                {
                                    DoctorDetails.review?.length ? DoctorDetails.review.map((review , index) => (
                                        <div className='each-review' key={index}>
                                            <div className='each-review-data'>
                                                <div>
                                                    <h6 className='each-review-title'>{review.user}</h6>
                                                    <RatingStar
                                                        id="clickable"
                                                        rating={review.rating}
                                                        numberOfStar={5}
                                                        maxScore={5}
                                                    />
                                                </div>
                                                <div>
                                                    <p>{review.comment}</p>
                                                </div>
                                            </div>
                                        </div> 
                                    ))
                                    :
                                    <div>
                                        <h6>لا يوجد تقييم لهذا المنتج</h6>
                                    </div>
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="location" title={<div className='product_description-map-tab'>{t('location')} <IconLocation /></div>}>
                            <div className='location-google-maps'>
                                {DoctorDetails.lat && DoctorDetails.lang &&<Maps latitude={DoctorDetails.lat} longitude={DoctorDetails.lang} />}
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            { isBooked && <Book userID = {userID} valid_id={valid_id} isBooked={isBooked} setIsBooked={setIsBooked} />}
            { isImgFull && <ImgFullscreen allImages={allImages} imgUrl={getFirstImg} isImgFull={isImgFull} setIsImgFull={setIsImgFull}/>}
        </>
    )
}

export default MaritalID