import React, { useEffect, useMemo, useRef, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Product_Description.css'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Maps from '../Maps/Maps';
import RelatedProducts from '../Related_Products/RelatedProducts';
import { Link, useHistory } from 'react-router-dom';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import "swiper/modules/thumbs/thumbs.min.css";
import "swiper/modules/free-mode/free-mode.min.css";
import { useDispatch } from 'react-redux'
import { addProduct, fetchCart } from '../../store/cartSlice';
// import { addProductToWishList, deleteProductFromWishList, fetchWishlist } from '../../store/wishListSlice';
import Book from '../BookNow/Book';
import SliderImage from 'react-zoom-slider';
import ImgFullscreen from '../ImgFullscreen/ImgFullscreen';
import { RatingStar } from 'rating-star';
import Share from '../Share/Share';
import AddToFavourite from '../AddToFavourite/AddToFavourite';
import IconLocation from '../Icons/IconLocation';
import IconReview from '../Icons/IconReview';
import IconDesc from '../Icons/IconDesc';
import DelayLoading from '../DeleayLoading/DelayLoading';
import swal from 'sweetalert';

const ProductDescription = () => {
      const { t } = useTranslation();
      const history = useHistory();
      const params = history.location.search
      const editParam = params.split("?")[1].split("&").reduce((acc, curr) => {
            let temp = curr.split("=")
            acc[temp[0]] = temp[1]
            return { ...acc }
      })
      const valid_id = editParam.split("=")[1];
      const dispatch = useDispatch();
      const userID = localStorage.getItem("user_id");
      const lang = localStorage.getItem("lang")
      const [isBooked, setIsBooked] = useState(false)
      const [isImgFull, setIsImgFull] = useState(false)
      const [ProductDetails, setProductDetails] = useState({});
      const [getFirstImg, setGetFirstImg] = useState('');
      const [allReview, setAllReview] = useState([]);
      const [showed , setShowed] = useState(false)

      const imgUrl = "https://zafaf-kw.com/dashboard/storage/app/public/product/";

      const FetchProductDesc = async () => {
            await axios(`https://zafaf-kw.com/dashboard/api/v1/products/productId?lang=${lang}&product_id=${valid_id}`)
            .then((result) => {
                  setProductDetails(result.data.product);
                  setShowed(true)
            }).catch((err) => {
                  setShowed(true)
            })
            
      }

      // needed handlers add to cart
      const cardQuantity = 1;
      const addToCart = () => {
            dispatch(addProduct({ user_id: parseInt(userID), product_id: valid_id, quantity: cardQuantity, callBack: () => dispatch(fetchCart(parseInt(userID))) }))
      }
      
      const allImages = useMemo(() => ProductDetails.images && ProductDetails.images.map((img) => (
            { image: imgUrl + img }
      )), [ProductDetails.images]);

      useMemo(() => ProductDetails.images && ProductDetails.images.map((img , index) => (
            setGetFirstImg(imgUrl+img)
      )), [ProductDetails.images]);

      const getImgByRef = useRef();

      useMemo(() => ProductDetails.review && ProductDetails.review.map((review , index) => (
            setAllReview(review)
      )), [ProductDetails.review]);

      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);

      useEffect(() => { 
            FetchProductDesc()
      }, []);

      const handleModelOpened = () => {
            if (localStorage.getItem('user_id')) {
                  setIsBooked(!isBooked)
            } else {
                  document.documentElement.dir === 'rtl' ? 
                  swal({
                        title: " قم بالتسجيل أولا",
                        icon: "warning",
                        button: "OK!",
                  })
                  : 
                  swal({
                        title: "please first login",
                        icon: "warning",
                        button: "OK!",
                  });
            }
      }

      return (
            <div className='product_desc-section'>
                  {showed ? <div className='product_description'>
                        <div className='product_description-carousel'>
                              <div className='product_description-carousel-header'>
                                    <div>
                                          {ProductDetails.name ?? <h4>{ProductDetails?.name}</h4>}
                                          <Link className='product_description-seller' to={`/ServiceProviderDesc?seller_id=${ProductDetails.sellerId}`}>{ProductDetails?.seller}</Link>
                                    </div>
                                    <div>
                                          <Share />
                                          <AddToFavourite valid_id={valid_id}/>
                                    </div>
                              </div>
                              <div className='product_description-carousel-content' ref={getImgByRef}>
                                    {
                                    allImages && allImages?.length > 0 &&
                                    <SliderImage
                                          data={allImages}
                                          width="700px"
                                          showDescription={false}
                                          direction="right"
                                    />
                                    }
                                    <span className='product_description-fullscreen' onClick={() => setIsImgFull(!isImgFull)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 384.97 384.97">
                                                <g id="Fullscreen">
                                                      <path d="M384.97,12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833,0-11.922,5.39-11.934,12.223    c0,6.821,5.101,11.838,11.934,11.838h96.062l-0.193,96.519c0,6.833,5.197,12.03,12.03,12.03c6.833-0.012,12.03-5.197,12.03-12.03    l0.193-108.369c0-0.036-0.012-0.06-0.012-0.084C384.958,12.09,384.97,12.066,384.97,12.03z"/>
                                                      <path d="M120.496,0H12.403c-0.036,0-0.06,0.012-0.096,0.012C12.283,0.012,12.247,0,12.223,0C5.51,0,0.192,5.317,0.192,12.03    L0,120.399c0,6.833,5.39,11.934,12.223,11.934c6.821,0,11.838-5.101,11.838-11.934l0.192-96.339h96.242    c6.833,0,12.03-5.197,12.03-12.03C132.514,5.197,127.317,0,120.496,0z"/>
                                                      <path d="M120.123,360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0,257.833,0,264.667v108.092    c0,0.036,0.012,0.06,0.012,0.084c0,0.036-0.012,0.06-0.012,0.096c0,6.713,5.317,12.03,12.03,12.03h108.092    c6.833,0,11.922-5.39,11.934-12.223C132.057,365.926,126.956,360.909,120.123,360.909z"/>
                                                      <path d="M372.747,252.913c-6.833,0-11.85,5.101-11.838,11.934v96.062h-96.242c-6.833,0-12.03,5.197-12.03,12.03    s5.197,12.03,12.03,12.03h108.092c0.036,0,0.06-0.012,0.084-0.012c0.036-0.012,0.06,0.012,0.096,0.012    c6.713,0,12.03-5.317,12.03-12.03V264.847C384.97,258.014,379.58,252.913,372.747,252.913z"/>
                                                </g>
                                          </svg>
                                    </span>
                              </div>
                              <div className='product_description-carousel-footer'>
                                    <div>
                                          {ProductDetails.name ?? <h4>{ProductDetails?.name}</h4>}
                                          {ProductDetails?.reviewCount > 0 && <span className='product_description-review'>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 53.385 51.096">
                                                      <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M25.27,1.777,18.754,14.988,4.176,17.113a3.2,3.2,0,0,0-1.766,5.448L12.956,32.839,10.462,47.358a3.191,3.191,0,0,0,4.63,3.363l13.042-6.855,13.042,6.855a3.194,3.194,0,0,0,4.63-3.363L43.311,32.839,53.858,22.562a3.2,3.2,0,0,0-1.766-5.448L37.513,14.988,31,1.777A3.2,3.2,0,0,0,25.27,1.777Z" transform="translate(-1.441 0.001)" fill="#ffbe44" />
                                                </svg> */}
                                                <RatingStar
                                                      id="clickable"
                                                      rating={ProductDetails?.rating}
                                                      numberOfStar={1}
                                                      maxScore={1}
                                                />
                                                {ProductDetails?.reviewCount && <h6>{ProductDetails?.reviewCount} ( {t('review')} {ProductDetails.rating} )</h6>}
                                          </span>}
                                    </div>
                                    <div>
                                          {
                                          ProductDetails.discount > 0 && 
                                          <div className='product_description-discount'>
                                                <b>{ProductDetails?.discount}%</b>
                                                <span>{t('discount')}</span> 
                                          </div>
                                          }
                                          <div className='product_description-salary' id={ProductDetails?.discount ? 'product-salary-withDiscount' : 'product-salary-withoutDiscount'}>
                                                {ProductDetails.discount > 0 &&<del>{ProductDetails?.unit_price} {t('currency')}</del>}
                                                <span>{ProductDetails?.totalPrice} {t('currency')}</span>
                                          </div>
                                          <div className='product_description-booking'>
                                                {
                                                      (ProductDetails?.type == 0) &&
                                                      <button className='product-add-btn' onClick={addToCart}>{t('add-to-cart')}</button>
                                                }
                                                {
                                                      (ProductDetails.type === 1) &&
                                                      <button className='product-add-btn' onClick={addToCart}>{t('add-to-cart')}</button>
                                                }
                                                {
                                                      (ProductDetails.type === 2) &&
                                                      <button className='product-booking-btn' onClick={() => handleModelOpened()}>
                                                            {t('book-now')}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none">
                                                                  <path d="M8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14H16C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12H8Z" fill="#fff"/>
                                                                  <path d="M7 17C7 16.4477 7.44772 16 8 16H12C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18H8C7.44772 18 7 17.5523 7 17Z" fill="#fff"/>
                                                                  <path fillRule="evenodd" clipRule="evenodd" d="M8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V4.10002C3.71776 4.56329 2 6.58104 2 9V17C2 19.7614 4.23858 22 7 22H17C19.7614 22 22 19.7614 22 17V9C22 6.58104 20.2822 4.56329 18 4.10002V3C18 2.44772 17.5523 2 17 2C16.4477 2 16 2.44772 16 3V4H8V3ZM20 10H4V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V10ZM4.17071 8C4.58254 6.83481 5.69378 6 7 6H17C18.3062 6 19.4175 6.83481 19.8293 8H4.17071Z" fill="#fff"/>
                                                            </svg>
                                                      </button>
                                                }
                                                {
                                                      (ProductDetails.type === 3) &&
                                                      <a className='product-call-btn' href={`tel:+965${ProductDetails?.phone}`}>
                                                            {t('call-now')}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.518 26.568"><path id="Icon_feather-phone" data-name="Icon feather-phone" d="M28.183,21.767V25.54a2.516,2.516,0,0,1-2.742,2.516,24.892,24.892,0,0,1-10.855-3.861A24.527,24.527,0,0,1,7.04,16.647,24.892,24.892,0,0,1,3.178,5.742,2.516,2.516,0,0,1,5.681,3H9.455A2.516,2.516,0,0,1,11.97,5.163a16.15,16.15,0,0,0,.88,3.534,2.516,2.516,0,0,1-.566,2.654l-1.6,1.6A20.125,20.125,0,0,0,18.234,20.5l1.6-1.6a2.516,2.516,0,0,1,2.654-.566,16.15,16.15,0,0,0,3.534.88,2.516,2.516,0,0,1,2.163,2.553Z" transform="translate(-2.417 -2.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"></path></svg>
                                                      </a>
                                                }
                                          </div>
                                    </div>
                              </div>
                        </div>
                        <div className='product_description-details'>
                              <Tabs
                                    defaultActiveKey="desc"
                                    id="justify-tab-example"
                                    className="mb-3"
                                    justify
                              >
                                    <Tab className='product_description-desc' eventKey="desc" title={<div className='product_description-desc-tab'>{t('desc')} <IconDesc /></div>}>
                                          <div className='product_description-details-text'>
                                                <ol>
                                                {
                                                ProductDetails?.details?.split("\r\n").map((text , index) => (
                                                      <li className='mb-2'>{text}</li>
                                                ))
                                                }
                                                </ol>
                                          </div>
                                    </Tab>
                                    <Tab className='product_description-reviewing' eventKey="reviewing" title={<div className='product_description-review-tab'>{t('reviewing')} <IconReview /></div>}>
                                          <div className="reviewing-container">
                                                {
                                                      ProductDetails?.review?.length ? ProductDetails?.review.map((review , index) => (
                                                            <div className='each-review' key={index}>
                                                                  <div className='each-review-data'>
                                                                        <div>
                                                                              <h6 className='each-review-title'>{review.user}</h6>
                                                                              <RatingStar
                                                                                    id="clickable"
                                                                                    rating={review.rating}
                                                                                    numberOfStar={5}
                                                                                    maxScore={5}
                                                                              />
                                                                        </div>
                                                                        <div>
                                                                              <p>{review.comment}</p>
                                                                        </div>
                                                                  </div>
                                                            </div> 
                                                      ))
                                                      :
                                                      <div>
                                                            <h6>لا يوجد تقييم لهذا المنتج</h6>
                                                      </div>
                                                }
                                          </div>
                                    </Tab>
                                    <Tab className='product_description-location' eventKey="location" title={<div className='product_description-map-tab'>{t('location')} <IconLocation /></div>}>
                                          <div className='location-google-maps'>
                                                {ProductDetails.lat && ProductDetails.long && <Maps latitude={ProductDetails.lat} longitude={ProductDetails.long} />}
                                          </div>
                                    </Tab>
                              </Tabs>
                        </div>

                        <RelatedProducts categoryIds={ProductDetails.category_ids} />
                  </div> 
                  :
                  <div>
                        <DelayLoading />
                  </div>
                  }
                  { isBooked && <Book userID = {userID} valid_id={valid_id} isBooked={isBooked} setIsBooked={setIsBooked} />}
                  { isImgFull && <ImgFullscreen allImages={allImages} imgUrl={getFirstImg} isImgFull={isImgFull} setIsImgFull={setIsImgFull}/>}
            </div>
      )
}

export default ProductDescription;