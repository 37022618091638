import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import './SocialLogin.css'
import { LoginSocialGoogle , LoginSocialFacebook } from 'reactjs-social-login';
import { FacebookLoginButton , GoogleLoginButton } from 'react-social-login-buttons';
import { TiSocialFacebook, TiSocialGooglePlusCircular } from 'react-icons/ti';
import { CgGoogle } from 'react-icons/cg';
import { FcGoogle } from 'react-icons/fc';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
const LoginWithSocial = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [provider, setProvider] = useState('');
    const [dataIsResponsed, setDataIsResponsed] = useState(false);
    const [googleProfile, setGoogleProfile] = useState({});
    const [facebookProfile, setFacebookProfile] = useState({});
    const onLoginStart = useCallback(() => {
        alert('login start');
    }, []);

    const submitRegisterWithGoogle = async () => {
        if (googleProfile) {
            await axios
                .post(
                    `https://zafaf-kw.com/dashboard/api/v1/auth/social-login?
                    social_id=${googleProfile?.sub}&
                    login_medium=${"google"}&
                    f_name=${googleProfile?.family_name}&  
                    l_name=${googleProfile?.given_name}&
                    password=${"12345678"}&
                    token=${googleProfile?.access_token}&
                    email=${googleProfile?.email}
                    `
                )
                .then((result) => {
                    if (result.data.status) {
                        localStorage.setItem("user_id", result.data.user.id ?? 1000);
                        document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success("Register Successfully")
                        history.push('/')
                    } 
                    else if (!result.data.status) {
                        document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل بحساب جوجل الخاص بك") : toast.error("Register not completed")
                    }
                })
        } else{
            document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل") : toast.error("Register not completed")
        }
    };
    useEffect(() => {
        dataIsResponsed && submitRegisterWithGoogle()
    } , [provider , googleProfile , dataIsResponsed])
    
    const submitRegisterWithFacebook = async () => {
        if(facebookProfile) {
            await axios
                .post(
                    `https://zafaf-kw.com/dashboard/api/v1/auth/social-login?
                    social_id=${facebookProfile?.userID}&
                    login_medium=${facebookProfile?.graphDomain}&
                    f_name=${facebookProfile?.first_name}&  
                    l_name=${facebookProfile?.last_name}&
                    password=${"12345678"}&
                    token=${facebookProfile?.accessToken}&
                    email=${facebookProfile?.email}`
                )
                .then((result) => {
                    if (result.data.status) {
                        localStorage.setItem("user_id", result.data.user.id ?? 1000);
                        document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success("Register Successfully")
                        history.push('/')
                    } else if (!result.data.status) {
                        document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل") : toast.error("Register not completed")
                    }
                })
        } else{
            document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل") : toast.error("Register not completed")
        }
    };


    return (
        <div className='login-socials'>
            <h4 className='login-others-title'>{t('or-register-with')}</h4>
            <div className='login-with-social'>
                <div className='login-google'>
                    <LoginSocialGoogle
                        client_id = '360120999528-kgijcf9906vd6c75nlp2gi20bh4r4djj.apps.googleusercontent.com'
                        scope = "openid profile email"
                        discoveryDocs = "claims_supported"
                        access_type = "offline"
                        onResolve = {({ provider , data }) => {
                            setProvider(provider);
                            setGoogleProfile(data);
                            setDataIsResponsed(true)
                        }}
                        onReject = {err => {
                            console.log(err);
                        }}
                        className='login-google-btn'
                    >
                        <FcGoogle></FcGoogle>
                        Login with google
                    </LoginSocialGoogle>
                </div>
                {/* <div className='login-facebook'>
                    <LoginSocialFacebook
                        appId='851411042606465'
                        fieldsProfile={
                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                        }
                        >
                        <TiSocialFacebook></TiSocialFacebook>
                    </LoginSocialFacebook>
                </div> */}
            </div>
        </div>
    )
}

export default LoginWithSocial