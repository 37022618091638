import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from '../Components/Navbar/Navbar';
import Home from '../Pages/Home/Home';
import About from '../Pages/About/About';
import Offers from '../Pages/Offers/Offers';
import Contact from '../Pages/Contact-us/Contact';
import SpecialOrder from '../Pages/SpecialOrder/SpecialOrder';
import Footer from '../Components/Footer/Footer';
import Conditions from '../Pages/Conditions/Conditions';
import Profile from '../Components/Profile/Profile';
import Cart from '../Pages/Cart/Cart';
import Login from '../Components/Registeration/Login';
import Favourites from '../Pages/Favourits/Favourits'
import ProductDescription from '../Components/Product-Description/ProductDescription';
import SeeMore from '../Components/See-More/SeeMore';
import SubCategory from '../Components/Sub-Category/SubCategory';
import Category from '../Pages/Sections/Category';
import MyOrders from '../Pages/MyOrders/MyOrders';
import ServiceProviderDesc from '../Components/Service_Provider_desc/ServiceProviderDesc';
import AllSections from '../Components/AllSections/AllSections';
import SearchResults from '../Components/SearchResults/SearchResults';
import Error from '../Pages/Error/Error';
import PageLoading from '../Components/Page_Loading/PageLoading';
import ScrollToTop from "react-scroll-to-top";
import SellerRegister from '../Components/Seller/SellerRegister';
import SellerLogin from '../Components/Seller/SellerLogin';
import MaritalCounselling from '../Components/MaritalCounselling/MaritalCounselling';
import MaritalID from '../Components/MaritalID/MaritalID';
import ResultFilter from '../Components/ResultFilterData/ResultFilter';
import Payment from '../Pages/PaymentCompleted/PaymentCompleted';
import PaymentCompleted from '../Pages/PaymentCompleted/PaymentCompleted';
import { useState } from 'react';
import SeeMorePartners from '../Components/SeeMorePartners/SeeMorePartners';
import { useTranslation } from 'react-i18next';

const Routes = () => {
    const [zafafContent , setZafafContent] = useState(false);
    const [myUrlPath , setMyUrlPath] = useState();
    useEffect(() => {
        setZafafContent(false);
        setTimeout(() => {
            setZafafContent(true);
        }, 2500);
    } , [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        const handleScroll = event => {
                setScrollTop(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
                window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    useEffect(() => {
        setMyUrlPath(window?.location?.pathname)
    } , [window?.location?.pathname])
    return (
        <Router>
            <div className='app'>
                <PageLoading />
                {
                    zafafContent && <div className='zafaf-app-content'>
                        <Navbar />
                        {myUrlPath === '/' && <div className='hide-in-desktop' style={{height:'50px'}}></div>}
                        <div >
                            <Switch>
                                <Route exact path="/">
                                    <Home />
                                </Route>
                                <Route path="/about">
                                    <About />
                                </Route>
                                <Route path="/category">
                                    <Category />
                                </Route>
                                <Route path="/offers">
                                    <Offers />
                                </Route>
                                <Route path="/contact">
                                    <Contact />
                                </Route>
                                <Route path="/SpecialOrder">
                                    <SpecialOrder />
                                </Route>
                                <Route path="/conditions">
                                    <Conditions />
                                </Route>
                                <Route path="/profile">
                                    <Profile />
                                </Route>
                                <Route path="/cart">
                                    <Cart />
                                </Route>
                                <Route path="/favourites">
                                    <Favourites />
                                </Route>
                                <Route path="/productDescription">
                                    <ProductDescription />
                                </Route>
                                <Route path="/maritalCounselling">
                                    <MaritalCounselling />
                                </Route>
                                <Route path="/maritalId">
                                    <MaritalID />
                                </Route>
                                <Route path="/login">
                                    <Login />
                                </Route>
                                <Route path="/seemore">
                                    <SeeMore />
                                </Route>
                                <Route path="/seemorepartners">
                                    <SeeMorePartners />
                                </Route>
                                <Route path="/subcategory">
                                    <SubCategory />
                                </Route>
                                <Route path="/myOrders">
                                    <MyOrders />
                                </Route>
                                <Route path="/ResultFilter">
                                    <ResultFilter />
                                </Route>
                                <Route path="/serviceProviderDesc">
                                    <ServiceProviderDesc />
                                </Route>
                                <Route path="/allsections">
                                    <AllSections />
                                </Route>
                                <Route path="/searchresults">
                                    <SearchResults />
                                </Route>
                                <Route path="/SellerLogin">
                                    <SellerLogin />
                                </Route>
                                <Route path="/paymentCompleted">
                                    <PaymentCompleted />
                                </Route>
                                <Route path="/SellerRegister">
                                    <SellerRegister />
                                </Route>
                                <Route path="">
                                    <Error />
                                </Route>
                                <Route path="*">
                                    <Error />
                                </Route>
                            </Switch>
                        </div>
                        <Footer />
                        <ScrollToTop smooth color="#fff" />
                    </div>
                }
            </div>
        </Router>
    )
}

export default Routes;