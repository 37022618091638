import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import Loading from '../Loading/Loading';
import ProductCard from '../productCard/productCard';
import './ResultFilter.css'

const ResultFilter = () => {
    const {t} = useTranslation();
    const [spinner, setSpinner] = useState(true);
    const allDataFiltered = JSON.parse(localStorage.getItem('dataIsFiltered'));
    return (
        <div className='result-filter'>
            <h3 className='result-filter-title'>{t('search-results')}</h3>
            <div className='result-filter-container'>
                <>
                    <Swiper
                        pagination={{
                                clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            480: {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            540: {
                                slidesPerView: 2.5,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3.5,
                                spaceBetween: 10,
                            },
                            1024: {
                                slidesPerView: 5.5,
                                spaceBetween: 10,
                            },
                        }}
                        dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                        className="mySwiper"
                    >
                        {/* {spinner && <Loading />} */}
                        {
                            allDataFiltered?.length > 0 ? allDataFiltered?.map((dataFilter , index) => (
                                <SwiperSlide key={index}>
                                        <ProductCard product={{...dataFilter , id:dataFilter?.id ?? dataFilter?.product_id}} />
                                </SwiperSlide>
                            ))
                            :
                            <div className='no-products'>
                                <span>{t('no-products')}</span>
                            </div>
                        }
                    </Swiper>
                </>
            </div>
        </div>
    )
}

export default ResultFilter;