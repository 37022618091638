import axios from 'axios'
import React, { useEffect, useState } from 'react'
import 'font-awesome/css/font-awesome.min.css';
import './social.css'
// https://zifaf.site/api/v1/socialMedia
const SocialMedia = () => {
    const [allSocialLinks , setAllSocialLinks] = useState([])
    const FetchSocialLinks = async () => {
        await axios.get("https://zafaf-kw.com/dashboard/api/v1/socialMedia")
        .then((result) => {
            if (result.data.status) {
                setAllSocialLinks(result.data.socials)
            }
        })
    }
    useEffect(() => {
        FetchSocialLinks();
    } , [])
    return (
        <ul className="footer__social">
            {allSocialLinks.length > 0 && allSocialLinks.map((social , index) => (
                <li key={index}>
                    <a href={social.link} target='_blank' rel="noopener noreferrer" title={social.name}>
                        <i className={social.icon}></i>
                    </a>
                </li>
            ))}
        </ul>
    )
}

export default SocialMedia