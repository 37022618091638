import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import HttpApi from 'i18next-http-backend'
import LanguageDetector from "i18next-browser-languagedetector";
import translationEn from './locale/en/translation-en.json'
import translationAr from './locale/ar/translation-ar.json'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(HttpApi)
	.use(Backend)
	.init({
		lng : "ar",
		fallbackLng: "ar",
		debug: true,
            supportedLngs : ["ar" , "en"],
		resources: {
			ar: {
				translation: translationAr
			},
			en: {
				translation: translationEn
			}
		},
		detection: {
			order: ['path', 'cookie', 'htmlTag'],
			caches: ['cookie'],
		},
		backend: {
			loadPath: './locale/{{lng}}/translation.json',
		},
		react: {
			useSuspense: true
		}

	});
	

export default i18n;
