import React, { useEffect, useRef, useState, useCallback } from 'react';
// import {authentication} from '../../firebase-config';
// import firebase from "firebase/compat/app";
// import {RecaptchaVerifier , signInWithPhoneNumber } from "firebase/auth";
import {CgArrowRightO} from 'react-icons/cg';
import {TiSocialFacebook} from 'react-icons/ti';
import {FcGoogle} from 'react-icons/fc';
import './Login.css';
import { Link, useHistory } from 'react-router-dom';
import Flower from '../../Images/Vector Smart Object.png';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import Aos from 'aos';
import Register from './Register';
import axios from 'axios';
import { toast } from 'react-toastify';
import SocialLogin from './SocialLogin';
import { Col, Form, Spinner } from 'react-bootstrap';
import LoginWithSocial from './LoginWithSocial';

const Login = () => {
      const {t} = useTranslation();
      let history = useHistory();
      // const countryCode = "+965"
      const lang = localStorage.getItem('lang')
      const [addNewAcc, setAddNewAcc] = useState(false);
      const [yourPhoneNumber, setYourPhoneNumber] = useState("");
      const [isAgreeTerms, setIsAgreeTerms] = useState(false);      
      const [spinner, setSpinner] = useState(false);
      const [validated, setValidated] = useState(false);

      const submitUser = async () => {
            if (yourPhoneNumber.length === 8 && isAgreeTerms) {
                  await axios.post(
                  `https://zafaf-kw.com/dashboard/api/v1/auth/login?lang=${lang}&phone=${yourPhoneNumber}`
                  )
                  .then((result) => {
                        if (result.data.status) {
                              setSpinner(true)
                              setTimeout(() => {
                                    setSpinner(false)
                                    localStorage.setItem("user_id", result.data.user.id);
                                    localStorage.setItem("profileData", JSON.stringify(result.data.user));
                                    document.documentElement.dir === 'rtl' ? toast.success("تم تسجيل الدخول بنجاح") : toast.success("Login Successfully")
                                    !spinner && history.push("/")
                              }, 1000);
                        } else if(!result.data.status) {
                              document.documentElement.dir === 'rtl' ? toast.error("قم بتسجيل حساب أولاً ، رقم الجوال هذا لم يتم التسجيل به مسبقا") : toast.error(result.data.message)
                        } else {
                              document.documentElement.dir === 'rtl' ? toast.error(result.data.message) : toast.error(result.data.message)
                        }
                  });
            } else if (!isAgreeTerms){
                  swal({
                        title: document.documentElement.dir === 'rtl' ? "يجب الموافقه على الشروط والأحكام" : "Agree on Conditions & Terms",
                        icon: "warning",
                        button: document.documentElement.dir === 'rtl' ? "حاول مره أخرى" : "Try again!",
                  });
            } else {
                  swal({
                        title: document.documentElement.dir === 'rtl' ? "أدخل رقم الجوال" : "Enter Your Phone number 8 numbers",
                        icon: "warning",
                        button: document.documentElement.dir === 'rtl' ? "حاول مره أخرى" : "Try again!",
                  });
            }
      };

      const handleValidate = (e) => {
            e.preventDefault();
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
            }
            setValidated(true);
            submitUser()
      }

      useEffect( () => {
            Aos.init({duration : 1000})
      } , [])

      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);

      return (
            <>
                  {
                        !addNewAcc ?
                        <div className='registration' data-aos="fade-up">
                              <img className='img-fixed' src={Flower} alt="" />
                              <>
                                    <div className='registration-content'>
                                          <h4>{t('login')}</h4>
                                          <Form onSubmit={handleValidate} noValidate validated={validated}>
                                                <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                      <div className="form-phone-number">
                                                            <div className='col-2'>
                                                                  <div className='defaultCountry-kw-code' id={document.documentElement.dir === 'rtl' ? "kw-inp-left" : "kw-inp-right"}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="25px" viewBox="0 85.333 512 341.333"><path fill="#FFF" d="M0 85.337h512v341.326H0z"/><path fill="#6DA544" d="M0 85.337h512v113.775H0z"/><path fill="#D80027" d="M0 312.888h512v113.775H0z"/><path d="M166.957 312.889 0 426.663V85.337l166.957 113.774z"/></svg>
                                                                        <span>+965</span>
                                                                  </div>
                                                            </div>
                                                            <div className='col-10'>
                                                                  <div className='defaultCountry-kw-inp'>
                                                                        <Form.Control 
                                                                              required
                                                                              type={'tel'}
                                                                              placeholder='8888  8888'
                                                                              value={yourPhoneNumber} 
                                                                              onChange={(e) => setYourPhoneNumber(e.target.value)}  
                                                                              maxLength={8}
                                                                              minLength={8}
                                                                        />
                                                                        <Form.Control.Feedback type='invalid'>
                                                                              {t('Please-choose')} {t('phone')}
                                                                        </Form.Control.Feedback>
                                                                  </div>
                                                                  <div id="recaptcha-container"></div>
                                                            </div>      
                                                      </div>
                                                </Form.Group>
                                                <div className="form-check">
                                                      <input className="inp-cbx" id="cbx" type="checkbox" onChange={(e) => setIsAgreeTerms(e.target.checked)}/>
                                                      <label className="cbx" htmlFor="cbx">
                                                            <span>
                                                                  <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                                  </svg>
                                                            </span>
                                                            <span className='form-terms'>{t('Agreeto')} <Link to='/conditions'>{t('conditions')}</Link> </span>
                                                      </label>
                                                </div>
                                                <button type='submit' id='verify-phone-btn' >
                                                      <CgArrowRightO></CgArrowRightO>
                                                      <h3>{t('login')}</h3>
                                                </button>
                                          </Form>
                                          {
                                                spinner && 
                                                <div className="registration-loading">
                                                      <Spinner animation="border" role="status" variant="primary">
                                                            <span className="visually-hidden">Loading...</span>
                                                      </Spinner>
                                                </div>
                                          }
                                          {/* <SocialLogin /> */}
                                          <LoginWithSocial />
                                    </div>
                                    <div className='newacc-and-merchant'>
                                          <div className='new-account'>
                                                <h4>{t('do-not-have-acc')}</h4>
                                                <h4 className='create-new-account' onClick={() => {setAddNewAcc(!addNewAcc)}}>{t('create-account')}</h4>
                                          </div>
                                          <div className='login-as-merchant'>
                                                <Link to="/sellerRegister">{t('login-as-merchant-to-provide-service')}</Link>
                                          </div>
                                    </div>
                              </> 
                              
                        </div> 
                        : 
                        <Register addNewAcc={addNewAcc} setAddNewAcc={setAddNewAcc} />
                  }
                  
            </>
      )
}

export default Login;