import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import './OfferPlus.css'
import ProductCard from '../productCard/productCard';
import Loading from '../Loading/Loading';

const OfferPlus = () => {
      const [spinner, setSpinner] = useState(true);
      const lang = localStorage.getItem("lang")
      const [productsInOffers , setProductsInOffers] = useState([]);
      const fetchOffers = async () => {
            await axios(`https://zafaf-kw.com/dashboard/api/v1/products/offerProduct?lang=${lang}`)
            .then((result) => {
                  if (result.data.status) {
                        setSpinner(false);
                        setProductsInOffers(result.data.offers);
                  }
            })
      }
      useEffect(() => {
            fetchOffers()
      } , [lang]);

      const {t} = useTranslation();

      return (
            <div className='offers-plus'>
                  <div className='offers-plus-header'>
                        <h4>{t('zafaf-offers')}</h4>
                        {/* <Link to={`/seemore?id=${1}`}>{t('see_more')}</Link> */}
                  </div>
                  <div className='offers-plus-container row'>
                        {spinner && <Loading />}
                        {
                              productsInOffers.length > 0 ? productsInOffers.map((prodOffer) => (
                                    <div className='col-lg-2 col-md-3 col-xs-6 col-6' key={prodOffer.product?.id}>
                                          <ProductCard product={{...prodOffer.product , id:prodOffer.product?.id}} />
                                    </div>
                              ))
                              :
                              <div className='no-products'>
                                    <span>{t('no-products')}</span>
                              </div> 
                        }
                  </div>
                  {/* <div className='offers-plus-container'>
                        <Swiper
                              slidesPerView={"auto"}
                              pagination={{
                                    clickable: true,
                              }}
                              breakpoints={{
                                    320: {
                                          slidesPerView: 1.5,
                                          spaceBetween: 10,
                                    },
                                    480: {
                                          slidesPerView: 2.5,
                                          spaceBetween: 10,
                                    },
                                    640: {
                                          slidesPerView: 3.5,
                                          spaceBetween: 10,
                                    },
                                    768: {
                                          slidesPerView: 3.5,
                                          spaceBetween: 10,
                                    },
                                    1024: {
                                          slidesPerView: 5.5,
                                          spaceBetween: 10,
                                    },
                              }}
                              dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                              className="mySwiper"
                        >
                              {spinner && <Loading />}
                              {
                                    productsInOffers.length > 0 ? productsInOffers.map((prodOffer) => (
                                          <SwiperSlide key={prodOffer.product?.id}>
                                                <ProductCard product={{...prodOffer.product , id:prodOffer.product?.id}} />
                                          </SwiperSlide>
                                    ))
                                    :
                                    <div className='no-products'>
                                          <span>{t('no-products')}</span>
                                    </div> 
                              }
                        </Swiper>
                  </div> */}
            </div>
      )
}

export default OfferPlus