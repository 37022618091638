import Aos from 'aos';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Activation.css';
import { toast } from 'react-toastify';
import OTPInput, { ResendOTP } from "otp-input-react";

const Activation = (props) => {
      const {t} = useTranslation() 

      const [OTP, setOTP] = useState("");
      const renderButton = (buttonProps) => {
            return <button {...buttonProps}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26.616 26.616"><path id="Icon_material-message" data-name="Icon material-message" d="M26.955,3H5.662A2.658,2.658,0,0,0,3.013,5.662L3,29.616l5.323-5.323H26.955a2.669,2.669,0,0,0,2.662-2.662V5.662A2.669,2.669,0,0,0,26.955,3ZM24.293,18.97H8.323V16.308h15.97Zm0-3.992H8.323V12.316h15.97Zm0-3.992H8.323V8.323h15.97Z" transform="translate(-3 -3)" fill="#dfa63c"></path></svg> 
                  {t('re-msg')}
            </button>;
      };
      const renderTime = (remainingtime) => {
            return <span>{remainingtime} {t('time-msg')}</span>;
      };

      const [isVerified, setIsVerified] = useState(false);
      
      const verificationCode = async (e) => {
            e.preventDefault();
            props.verifyOTP(OTP);
            if (OTP && OTP.length === 6) {
                  // document.documentElement.dir === 'rtl' ? toast.success("تم التأكيد بنجاح") : toast.success("Verification successfully")
                  // history.push('/') 
            } else if (OTP.length !== 6) {
                  document.documentElement.dir === 'rtl' ? toast.error("يجب ان يحتوى كود التأكيد على 6 أرقام") : toast.error("Verification code is must be 6 numbers")
            } else {
                  document.documentElement.dir === 'rtl' ? toast.error("هناك خطأ فى كود التأكيد") : toast.error("Verification code is wrong")
            }
      }

      const resendOTP = async () => {
            props.requestOTP()
      }
      useEffect(() => {
            Aos.init({duration : 1000})
      } , []);
      
      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);
      return (
            <>
                  {
                        !isVerified && 
                        <div className='activation-code' data-aos="fade-up">
                              <div className='activation-code-content'>
                                    <h4 className='activation-code-title'>{t('verify-text')}</h4>
                                    <OTPInput 
                                          className="otp-input" 
                                          value={OTP} 
                                          onChange={setOTP} 
                                          autoFocus 
                                          OTPLength={6} 
                                          otpType="number" 
                                          disabled={false} 
                                    />
                                    <ResendOTP 
                                          className="otp-input-btn" 
                                          maxTime={90}
                                          renderTime={renderTime} 
                                          renderButton={renderButton} 
                                          onClick={resendOTP}
                                    />
                                    <button 
                                          className='activation-code-btn' 
                                          type='submit' 
                                          onClick={verificationCode}
                                    >
                                          {t('activation')}
                                    </button>
                              </div>
                        </div>
                  }
            </>
            
      )
}

export default Activation;