import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { adjustProduct, deleteProduct, fetchCart } from "../../store/cartSlice";

const CartItemComponent = ({ cartProduct }) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const user_id = localStorage.getItem("user_id");

  // handler Methods
    const addjustProduct = (updateCase) => {
        if (updateCase === "INCRESE") {
            dispatch(
                adjustProduct({
                order_id: cartProduct.id,
                user_id: parseInt(user_id),
                callBack: () => dispatch(fetchCart(parseInt(user_id))),
                quantity: cartProduct.quantity + 1,
                })
            );
            return;
        }
        if (updateCase === "DECRECE") {
            if (cartProduct.quantity - 1 <= 0) {
                removeProduct();
                return;
            }
            dispatch(
                adjustProduct({
                order_id: cartProduct.id,
                user_id: parseInt(user_id),
                callBack: () => dispatch(fetchCart(parseInt(user_id))),
                quantity: cartProduct.quantity - 1,
                })
            );
            return;
        }
    };

    const removeProduct = () => {
        dispatch(
        deleteProduct({
            order_id: cartProduct.id,
            user_id: parseInt(user_id),
            callBack: () => dispatch(fetchCart(parseInt(user_id))),
        })
        );
    };

    return (
        <div className="cart-product-module">
            <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4">
                    <div className="cart-product-module-img">
                        <img src={cartProduct.image ?? cartProduct.productImage} alt="" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-7 col-7">
                    <div className="cart-product-module-details">
                        {cartProduct.productName ? <span>{cartProduct.productName ?? cartProduct.name ?? cartProduct?.products?.name ?? cartProduct.products.productName}</span> : <span>{t('default-name')}</span>}
                        {cartProduct.seller ? <h6>{cartProduct.seller}</h6> : <h6>{t('default-name')}</h6>}
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                    <div className="cart-product-module-calc">
                        <div>
                            <button onClick={() => addjustProduct("INCRESE")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 455 455">
                                    <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5   455,242.5 "/>
                                </svg>
                            </button>
                        </div>
                        <div>
                            <span>{cartProduct.quantity >= 0 ? cartProduct.quantity : 0}</span>
                        </div>
                        <div>
                            <button onClick={() => addjustProduct("DECRECE")}>
                                {cartProduct.quantity === 1 ? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 72 72" id="emoji">
                                        <g id="color">
                                            <path fill="#FFFFFF" stroke="none" d="M51.7598,17H20.1525v37.65c0,4.0593,3.2907,5.6209,7.35,5.6209h16.9073c4.0593,0,7.35-1.5616,7.35-5.6209 V17z"/>
                                            <polyline fill="#FFFFFF" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" points="31,16 31,12 41,12 41,16"/>
                                            <polygon fill="#9b9b9a" stroke="none" points="51,37 51,46.5172 51,57.6207 48.3,60 33,60"/>
                                            <rect x="17" y="16" width="38" height="4" fill="#FFFFFF" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                        </g>
                                        
                                        <g id="line">
                                            <polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" points="31,16 31,12 41,12 41,16"/>
                                            <path fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M51,25v31c0,2.2091-1.7909,4-4,4H25c-2.2091,0-4-1.7909-4-4V25"/>
                                            <rect x="17" y="16" width="38" height="4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                            <line x1="41" x2="41" y1="28.25" y2="55" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                            <line x1="31" x2="31" y1="28.25" y2="55" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                        </g>
                                    </svg>
                                : 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" version="1.1" id="图层_1" x="0px" y="0px" viewBox="0 0 40 40" enableBackground="new 0 0 40 40">
                                        <path fill="#000" d="M26,20.5H14c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h12c0.3,0,0.5,0.2,0.5,0.5S26.3,20.5,26,20.5z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                    <div className="cart-product-module-salary">
                        <h6>{cartProduct.price} {t('currency')}</h6>
                    </div>
                </div>
                <div className={document.documentElement.dir === 'rtl' ? "cart-product-module-delete" : "cart-product-module-delete-en"}>
                    <span onClick={removeProduct}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 72 72" id="emoji">
                            <g id="color">
                                <path fill="#FFFFFF" stroke="none" d="M51.7598,17H20.1525v37.65c0,4.0593,3.2907,5.6209,7.35,5.6209h16.9073c4.0593,0,7.35-1.5616,7.35-5.6209 V17z"/>
                                <polyline fill="#FFFFFF" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" points="31,16 31,12 41,12 41,16"/>
                                <polygon fill="#f00" stroke="none" points="51,37 51,46.5172 51,57.6207 48.3,60 33,60"/>
                                <rect x="17" y="16" width="38" height="4" fill="#FFFFFF" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                            </g>
                            
                            <g id="line">
                                <polyline fill="none" stroke="#f00" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" points="31,16 31,12 41,12 41,16"/>
                                <path fill="none" stroke="#f00" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" d="M51,25v31c0,2.2091-1.7909,4-4,4H25c-2.2091,0-4-1.7909-4-4V25"/>
                                <rect x="17" y="16" width="38" height="4" fill="none" stroke="#f00" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                <line x1="41" x2="41" y1="28.25" y2="55" fill="none" stroke="#f00" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                                <line x1="31" x2="31" y1="28.25" y2="55" fill="none" stroke="#f00" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
                            </g>
                        </svg>
                    </span>
                </div>
                {/* <div className="col-lg-1 col-md-1 col-sm-1">
                </div> */}
            </div>
        </div>
    );
};

export default CartItemComponent;