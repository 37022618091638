import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import './slider.css'
import axios from 'axios';
import Aos from "aos";
import Loading from '../Loading/Loading';
import { Link } from 'react-router-dom';

const OurSlider = () => {
      const [sliders , setSliders] = useState([]);
      const [spinner, setSpinner] = useState(true);
      const fetchSliders = async () => {
            await axios("https://zafaf-kw.com/dashboard/api/v1/banners?banner_type=Main Banner")
            .then((result) => {
                  if (result.data.status) {
                        setSliders(result.data.banners);
                        setSpinner(false)
                  }
            })
      }

      useEffect(() => {
            fetchSliders();
            Aos.init({duration : 1000})
      } , [])
      return (
            <>
                  {
                  sliders.length > 0 && 
                  <div className='slider-container' data-aos="fade-up">
                        {spinner && <Loading />}
                        <Carousel fade={true}>
                              {
                                    sliders.length > 0 && sliders.map((sliderImg , index) => (
                                          <Carousel.Item key={index}>
                                                <img
                                                      className="d-block w-100"
                                                      src={sliderImg.bannerImage}
                                                      alt="First slide"
                                                      onClick={() => window.location.replace(sliderImg.url)}
                                                />
                                                {/* <a href={sliderImg.url}>
                                                </a> */}
                                                <div onClick={() => window.location.replace(sliderImg.url)} className='carousel-item-caption'>
                                                      {document.documentElement.dir === 'rtl' ? <span>{sliderImg.descraption_ar}</span> : <span>{sliderImg.descraption_en}</span>}
                                                </div>
                                          </Carousel.Item>
                                    ))
                              }
                        </Carousel>
                  </div>
                  }
            </>
      )
}

export default OurSlider;