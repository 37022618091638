import React from 'react'
import LoadingSpin from 'react-loading-spin'
import './DelayLoading.css'

const DelayLoading = () => {
    return (
        <div className='DelayLoading'>
            <LoadingSpin
                duration="2s"
                width="5px"
                timingFunction="ease-in-out"
                direction="alternate"
                size="100px"
                primaryColor="#f29900"
                secondaryColor="#f3f3f3"
                numberOfRotationsInAnimation={4}
            />
        </div>
    )
}

export default DelayLoading