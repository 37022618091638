import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './i18n';
import store from "./store";
import { Provider } from "react-redux";

ReactDOM.render(
      <Provider store={store()}>
            <Suspense fallback={(<div>Loading ...</div>)}>
                  <App />
            </Suspense>
      </Provider>, document.getElementById("root")
);
