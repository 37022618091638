import React from 'react';
import Screen from '../../Images/1576.png';
import GP from '../../Images/google-play.png';
import Apple from '../../Images/apple.png';
import './DownApp.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import Aos from "aos";


const DownApp = () => {
      const { t } = useTranslation();
      useEffect(() => {
            Aos.init({duration : 1000})
      } , [])
      return (
            <div className='download-app'>
                  <div className="download-app-content" data-aos="fade-left">
                        <div className="download-app-title">
                              <h2>{t('downTitle')}</h2>
                        </div>
                        {/* <div className="download-app-text">
                              <p>{t('downText')}</p>
                        </div> */}
                        <div className="btn__group btn__download">
                              <a href='https://play.google.com/store/apps' target='_blank' rel="noopener noreferrer">
                                    <img src={GP} alt=''/>
                                    <div>
                                          <span>{t('getIt')}</span> 
                                          <span>Google Play</span>
                                    </div>
                              </a>
                              <a href='https://apps.apple.com/us/app/zafaf-زفاف/id1665374884' target='_blank' rel="noopener noreferrer">
                                    <img src={Apple} alt=''/> 
                                    <div>
                                          <span>{t('getIt')}</span> 
                                          <span>App Store</span>
                                    </div>
                              </a>
                        </div>
                  </div>
                  <div className="download-app-img" data-aos="fade-up">
                        <img src={Screen} alt="" />
                  </div>
            </div>
      )
}

export default DownApp;