import axios from 'axios';
import React, { useState } from 'react'
import { Col, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import './SellerRegister.css'

const SellerRegister = () => {
    const {t} = useTranslation();
    const userID = localStorage.getItem("user_id");
    const [yourFirstName, setYourFirstName] = useState('');
    const [yourEmail, setYourEmail] = useState('');
    const [yourPhone, setYourPhone] = useState('');
    const [yourStoreAddress, setYourStoreAddress] = useState('');
    const [yourStoreName, setYourStoreName] = useState('');
    const [spinner, setSpinner] = useState(false);

    const formData = new FormData();
    formData.append('user_id' , userID);
    formData.append('name' , yourFirstName);
    formData.append('email' , yourEmail);
    formData.append('phone' , yourPhone);
    formData.append('address' , yourStoreAddress);
    formData.append('storeName' , yourStoreName);
    

    const registerSellerSubmit = async () => {
        if (yourFirstName && yourEmail && yourPhone && yourStoreAddress && yourStoreName) {
            await axios.post(`https://zafaf-kw.com/dashboard/api/v1/seller/registerSeller`, 
            formData,
            {
                headers: {
                    'enctype': 'multipart/form-data',
                },
            })
            .then((result) => {
                setSpinner(true)
                if (result.data.status) {
                    // setSpinner(true)
                    setTimeout(() => {
                        document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success(result.data.message)
                        setSpinner(false)
                    }, 1000);
                } else if (!result.data.status){
                    setSpinner(false)
                    document.documentElement.dir === 'rtl' ? toast.error("لم يتم إرسال العضويه") : toast.error("sent failed")
                }
            })
        }
    }

    const [validated, setValidated] = useState(false);
    const handleValidate = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        registerSellerSubmit()
    }
    return (
        <div className='seller-register'>
            <div className='seller-register-content'>
                <h4 className='seller-register-title'>{t('login-as-merchant')}</h4>
                <Form onSubmit={(e) => handleValidate(e)} noValidate validated={validated} className="row">
                    <Form.Group as={Col} md="6" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                            required 
                            type="text" 
                            name="" 
                            placeholder={t('name')}
                            value={yourFirstName}
                            onChange={(e) => setYourFirstName(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('name')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                                required 
                                type="email" 
                                name="" 
                                placeholder={t('e-mail')}
                                value={yourEmail}
                                onChange={(e) => setYourEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('email')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                            required 
                            type="tel" 
                            name="" 
                            placeholder={t('phone')}
                            value={yourPhone}
                            onChange={(e) => setYourPhone(e.target.value)}
                            maxLength={8}
                            minLength={8}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('phone')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="6" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                                required 
                                type="password" 
                                name="" 
                                placeholder={t('password')}
                                value={yourPassword}
                                onChange={(e) => setYourPassword(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('password')}
                        </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group as={Col} md="6" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                                required 
                                type="text" 
                                name="" 
                                placeholder={t('store_address')}
                                value={yourStoreAddress}
                                onChange={(e) => setYourStoreAddress(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('store_address')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                        <Form.Control
                                required 
                                type="text" 
                                name="" 
                                placeholder={t('store_name')}
                                value={yourStoreName}
                                onChange={(e) => setYourStoreName(e.target.value)}
                        />
                        <Form.Control.Feedback type='invalid'>
                                {t('Please-choose')} {t('store_name')}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group className="mb-3" as={Col} md="6" cm="6" controlId="validationCustom01">
                        <label htmlFor='add-single-img' className={ yourSellerImage ? 'is-choosed-img' : 'choose-img'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42.121 42.121">
                                <g id="Icon_feather-image" data-name="Icon feather-image" transform="translate(1.5 1.5)">
                                    <path id="Path_17340" data-name="Path 17340" d="M8.778,4.5H38.722A4.278,4.278,0,0,1,43,8.778V38.722A4.278,4.278,0,0,1,38.722,43H8.778A4.278,4.278,0,0,1,4.5,38.722V8.778A4.278,4.278,0,0,1,8.778,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                    <path id="Path_17341" data-name="Path 17341" d="M16.917,13.708A3.208,3.208,0,1,1,13.708,10.5,3.208,3.208,0,0,1,16.917,13.708Z" transform="translate(-1.944 -1.944)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                    <path id="Path_17342" data-name="Path 17342" d="M41.722,25.694,31.028,15,7.5,38.528" transform="translate(-3.222 -0.028)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                </g>
                            </svg>
                            {t('seller_img')}
                            <input 
                                type="file" 
                                id="add-single-img" 
                                value={""}
                                onChange={(e) => setYourSellerImage(e.target.files[0])}
                            />
                        </label>
                    </Form.Group>
                    <Form.Group className="mb-3" as={Col} md="6" cm="6" controlId="validationCustom01">
                        <label htmlFor='add-single-video' className={yourStorImage ? 'is-choosed-video' :'choose-video'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 42.121 42.121">
                                <g id="Icon_feather-image" data-name="Icon feather-image" transform="translate(1.5 1.5)">
                                    <path id="Path_17340" data-name="Path 17340" d="M8.778,4.5H38.722A4.278,4.278,0,0,1,43,8.778V38.722A4.278,4.278,0,0,1,38.722,43H8.778A4.278,4.278,0,0,1,4.5,38.722V8.778A4.278,4.278,0,0,1,8.778,4.5Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                    <path id="Path_17341" data-name="Path 17341" d="M16.917,13.708A3.208,3.208,0,1,1,13.708,10.5,3.208,3.208,0,0,1,16.917,13.708Z" transform="translate(-1.944 -1.944)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                    <path id="Path_17342" data-name="Path 17342" d="M41.722,25.694,31.028,15,7.5,38.528" transform="translate(-3.222 -0.028)" fill="none" stroke="#c79632" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"/>
                                </g>
                            </svg>
                            {t('store_img')}
                            <input 
                                type="file" 
                                id="add-single-video" 
                                value={""}
                                onChange={(e) => setYourStorImage(e.target.files[0])}
                            />
                        </label>
                    </Form.Group> */}
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <button onClick={() => {registerSellerSubmit()}} className='login-seller-btn' id="new-verify-phone-btn" type='submit'>
                            <h3>{t('provide_service')}</h3>
                        </button>
                    </Form.Group>
                </Form>
            </div>
            {
                spinner && 
                <div className="seller-loading">
                    <Spinner animation="border" role="status" variant="primary">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            }
        </div>
    )
}

export default SellerRegister;