import React, { createRef, useEffect, useState } from 'react'
import { CgArrowRightO } from 'react-icons/cg'
// import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './Register.css'
import Flower from '../../Images/Vector Smart Object.png'
import Activation from './Activation';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import Aos from 'aos';
import axios from 'axios';
import { toast } from 'react-toastify';
import Col from 'react-bootstrap/esm/Col';      
import { Link, useHistory } from 'react-router-dom';
import {authentication} from '../../firebase-config'
import {RecaptchaVerifier , signInWithPhoneNumber , getAuth} from "firebase/auth";
import { Spinner } from 'react-bootstrap';

const Register = (props) => {
      const {t} = useTranslation();
      let history = useHistory();
      const countryCode = "+965";
      const lang = localStorage.getItem('lang')
      const [isLogged, setIsLogged] = useState(false);
      const [yourName, setYourName] = useState('');
      const [yourCountry] = useState('الكويت');
      const [yourCity, setYourCity] = useState('');
      const [yourEmail, setYourEmail] = useState('');
      const [PhoneNumber, setPhoneNumber] = useState("");
      const [userID, setUserID] = useState("");
      const [OTP, setOTP] = useState('');
      const [validated, setValidated] = useState(false);
      const [spinner, setSpinner] = useState(false);

      const createOtp = () => {
            const recaptchaVerifier = new RecaptchaVerifier(
                  "recaptcha-container",
                  {
                        'size': 'invisible',
                        'callback': (response) => {
                              // reCAPTCHA solved, allow signInWithPhoneNumber.
                        },
                        'expired-callback': (response) => {
                              recaptchaVerifier.reset();
                                    // Reset reCAPTCHA?
                        }
                  },
                  authentication
            );
            let fullNumber = countryCode+PhoneNumber;
            signInWithPhoneNumber(authentication , fullNumber , recaptchaVerifier)
            .then((confirmationResult) => {
                  window.confirmationResult = confirmationResult;
                  swal({
                        title: document.documentElement.dir === 'rtl' ? "تم إرسال كود التأكيد بنجاح" : "Send otp successfully",
                        icon: "success",
                        button: document.documentElement.dir === 'rtl' ? "تم":"OK",
                  });
            }).catch((error) => {
                  swal({
                        title: document.documentElement.dir === 'rtl' ? "فشل فى إرسال كود التأكيد " : "Failed sent OTP!",
                        icon: "warning",
                        button: document.documentElement.dir === 'rtl' ? "حاول مره أخرى":"Try again!",
                  });
                  
            });
      }

      const verifyOTP = (e) => {
            if (e && e.length === 6) {
                  let confirmationResult = window.confirmationResult;
                  confirmationResult.confirm(e)
                  .then((result) => {
                        const user = result.user;
                        document.documentElement.dir === 'rtl' ? toast.success("تم التأكيد بنجاح") : toast.success("Verification successfully")
                        history.push('/')   
                        submitRegister();
                  }).catch((error) => {
                        document.documentElement.dir === 'rtl' ? toast.error("!حدث خطأ حاول مره أخرى") : toast.error("An error happened, plz try again!")
                  });
            }
      }

      const handleValidate = (e) => {
            e.preventDefault()
            const form = e.currentTarget;
            if (form.checkValidity() === false) {
                  e.preventDefault();
                  e.stopPropagation();
            }
            setValidated(true);
            // requestotpCode()
            createOtp()
            setTimeout(() => {
                  setIsLogged(!isLogged);
            }, 3000);
      }

      const requestOTP = (e) => {
            // handleValidate()
            createOtp()
      }
      const submitRegister = async () => {
            if(yourName && yourCity && yourEmail && PhoneNumber.length === 8) {
                  await axios
                        .post(
                              `https://zafaf-kw.com/dashboard/api/v1/auth/register?lang=${lang}&login_medium=${'manual'}&name=${yourName}&country=${'Kuwait'}&city=${yourCity}&email=${yourEmail}&phone=${PhoneNumber}`
                        )
                        .then((result) => {
                              if (result.data.status) {
                                    setSpinner(true)
                                    setTimeout(() => {
                                          setSpinner(false)
                                          setUserID(result.data.user.id);
                                          localStorage.setItem("user_id", result.data.user.id);
                                          document.documentElement.dir === 'rtl' ? toast.success(result.data.message) : toast.success("Register Successfully")
                                          requestOTP();
                                          // setIsLogged(!isLogged);
                                    }, 1000);
                              } else if (!result.data.status) {
                                    // document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل ربما يكون الرقم مستخدم بالفعل ") : toast.error("Register not completed maybe phone number already used")
                                    document.documentElement.dir === 'rtl' ? toast.error(result.data.message[0].message) : toast.error(result.data.message[0].message)
                              }
                        })
            } else {
                  document.documentElement.dir === 'rtl' ? toast.error("لم يتم التسجيل") : toast.error("Register not completed")
            }
      };
      useEffect( () => {
            Aos.init({duration : 1000})
      } , []);
      
      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);

      return (
            <>
                  {
                        !isLogged ? 
                        <div className='add-new-account' data-aos="fade-up">
                              <img className='img-fixed' src={Flower} alt="" />
                              <div className='add-new-account-content'>
                                    <h4>{t('create-new-account')}</h4>
                                    <Form onSubmit={handleValidate} noValidate validated={validated} className="row">
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <Form.Control
                                                      required 
                                                      type="text" 
                                                      name="" 
                                                      placeholder={t('name')}
                                                      value={yourName}
                                                      onChange={(e) => setYourName(e.target.value)}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                      {t('Please-choose')} {t('name')}
                                                </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <Form.Control 
                                                      required
                                                      type="text" 
                                                      placeholder="" 
                                                      value={yourCountry} 
                                                      disabled
                                                />
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <Form.Control 
                                                      required
                                                      type="text" 
                                                      placeholder={t('city')}
                                                      value={yourCity}
                                                      onChange={(e) => setYourCity(e.target.value)}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                      {t('Please-choose')} {t('city')}
                                                </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <Form.Control 
                                                      required
                                                      type="email" 
                                                      placeholder="zafaf@gmail.com"
                                                      value={yourEmail}
                                                      onChange={(e) => setYourEmail(e.target.value)}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                      {t('Please-choose')} {t('e-mail')}
                                                </Form.Control.Feedback>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <div className="form-phone-number">
                                                      <div className='col-2'>
                                                            <div className='defaultCountry-kw-code' id={document.documentElement.dir === 'rtl' ? "kw-inp-left" : "kw-inp-right"}>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="25px" viewBox="0 85.333 512 341.333"><path fill="#FFF" d="M0 85.337h512v341.326H0z"/><path fill="#6DA544" d="M0 85.337h512v113.775H0z"/><path fill="#D80027" d="M0 312.888h512v113.775H0z"/><path d="M166.957 312.889 0 426.663V85.337l166.957 113.774z"/></svg>
                                                                  <span>+965</span>
                                                            </div>
                                                      </div>
                                                      <div className='col-10'>
                                                            <div className='defaultCountry-kw-inp'>
                                                                  <Form.Control 
                                                                        required
                                                                        type={'tel'}
                                                                        placeholder='8888  8888'
                                                                        value={PhoneNumber} 
                                                                        onChange={(e) => setPhoneNumber(e.target.value)}  
                                                                        maxLength={8}
                                                                        minLength={8}
                                                                  />
                                                                  <Form.Control.Feedback type='invalid'>
                                                                        {t('Please-choose')} {t('phone')}
                                                                  </Form.Control.Feedback>
                                                            </div>
                                                            {/* <div id="recaptcha-container"></div> */}
                                                      </div>      
                                                </div>
                                          </Form.Group>
                                          <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom01">
                                                <button className='add-new-account-btn' id="new-verify-phone-btn" type='submit'>
                                                      <CgArrowRightO></CgArrowRightO>
                                                      <h3>{t('create-new-account')}</h3>
                                                </button>
                                          </Form.Group>
                                    </Form>
                                    {
                                          spinner && 
                                          <div className="add-new-account-loading">
                                                <Spinner animation="border" role="status" variant="primary">
                                                      <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                          </div>
                                    }
                                    {/* <Form onSubmit={requestOTP}>
                                          <input dir='ltr' type="tel" value={PhoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}/>
                                          <button type='submit'>Send OTP</button>
                                    </Form> */}
                                    <div id='recaptcha-container'></div>
                              </div>
                              <div className='newacc-and-merchant'>
                                    <div className='have-account'>
                                          <h4>{t('do-have-acc')}</h4>
                                          <h4 className='have-account-btn' onClick={() => {props.setAddNewAcc(!props.addNewAcc)}}>
                                                <Link to="/login">{t('login')}</Link>
                                          </h4>
                                    </div>
                                    <div className='login-as-merchant'>
                                          <Link to="/sellerRegister">{t('login-as-merchant-to-provide-service')}</Link>
                                    </div>
                              </div>
                        </div> 
                        :
                        <>
                              {
                                    isLogged && <Activation phoneNumber={PhoneNumber} otpVal={OTP} verifyOTP={verifyOTP} requestOTP={requestOTP}/>
                              }
                        </> 
                  }
                  {/* <GenerateReCaptcha /> */}
            </>
      )
}

export default Register;