import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import './SeeMore.css';
import ProductCard from '../productCard/productCard';
import Loading from '../Loading/Loading';

const SeeMore = () => {
      const {t} = useTranslation();
      const [spinner, setSpinner] = useState(true);
      // get product id from params
      const history = useHistory();
      const params = history.location.search
      const blabla = params.split("?")[1].split("&").reduce((acc , curr) => {
            let temp = curr.split("=")
            acc[temp[0]] = temp[1]
            return {...acc}
      })
      const valid_id = blabla.split("=")[1];

      // Fetch products see more
      const lang = localStorage.getItem("lang")
      const [seeMores , setSeeMores] = useState([]);
      const fetchSeeMorePro = async () => {
            await axios(`https://zafaf-kw.com/dashboard/api/v1/categories/products?lang=${lang}&category_id=${valid_id}`)
            .then((result) => {
                  if (result.data.status) {
                        setSpinner(false)
                        setSeeMores(result.data.products);
                  }
            })
      }
      
      useEffect(() => {
            fetchSeeMorePro();
      } , [lang]);

      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);

      return (
            <div className='see-more'>
                  <h3 className='see-more-title'>{t('services')}</h3>
                  <div className='see-more-container'>
                        <div className='row see-more-content'>
                        {spinner && <Loading />}
                        {
                              seeMores.length > 0 ? seeMores.map((sm , index) => (
                                    <div className='col-lg-2 col-md-3 col-xs-6 col-6'>
                                          <ProductCard product={{...sm , id:sm?.id ?? sm?.product_id}} />
                                    </div>
                              ))
                              :
                              <div className='no-products'>
                                    <span>{t('no-products')}</span>
                              </div>
                        }
                        </div>
                  </div>
            </div>
      )
}

export default SeeMore;