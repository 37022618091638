import React, { useEffect, useState } from 'react'
import './Suggestions.css'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Aos from "aos";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Lazy , Autoplay } from 'swiper';

const Suggestions = () => {
      const { t } = useTranslation();
      const [suggestion , setSuggestion] = useState([]);
      const fetchSuggestions = async () => {
            const response = await axios("https://zafaf-kw.com/dashboard/api/v1/banners?banner_type=Footer Banner");
            setSuggestion(response.data.banners);
            return response.data.banners
      }

      useEffect(() => {
            fetchSuggestions()
            Aos.init({duration : 1000})
      } , []);

      return (
            <>
                  { suggestion.length > 0 && 
                  <div className='suggestions'>
                        <div className='suggestions-title' data-aos="fade-left">
                              <h3>{t('suggestions')}</h3>
                        </div>
                        <div className='suggestions-content' data-aos="fade-up">
                              <Swiper
                                    slidesPerView={"auto"}
                                    spaceBetween= {20}
                                    loop={true}
                                    autoplay={{
                                          delay: 2000,
                                          disableOnInteraction: false,
                                    }}
                                    lazy={true}
                                    autoplayspeed= {2000}
                                    centeredSlides= {true}
                                    centerpadding= {160}
                                    pagination={{
                                          clickable: true,
                                    }}
                                    breakpoints={{
                                          320: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          480: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          540: {
                                                slidesPerView: 1.6,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          768: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                          1024: {
                                                slidesPerView: 2.2,
                                                spaceBetween: 20,
                                                centeredSlides: true,
                                                centerPadding: 160
                                          },
                                    }}
                                    dir={'ltr'}
                                    modules={[Lazy , Autoplay]}
                                    className="mySwiper"
                              >
                                          { 
                                                suggestion.length > 0 ? suggestion.map((suggest , index) => (
                                                      <SwiperSlide key={index}>
                                                            <a href={suggest.url} className='slide-offer' id={'slide-offer-'+JSON.stringify(index)} key={index}>
                                                                  <img src={suggest.bannerImage} alt=''/>
                                                                  <div className='slide-caption-suggestion'>
                                                                        {document.documentElement.dir === 'rtl' ? <h6 style={{direction : "rtl"}}>{suggest.descraption_ar}</h6> : <h6 style={{direction : "ltr"}}>{suggest.descraption_en}</h6>}
                                                                  </div>
                                                            </a>
                                                      </SwiperSlide>
                                                ))
                                                : 
                                                <div className='no-products'>
                                                      <span>{t('no-products')}</span>
                                                </div>
                                          }
                              </Swiper>
                        </div>
                  </div>}
            </>
      )
}

export default Suggestions;