import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import server from "../api/api";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import swal from "sweetalert";

// initial state
const initialState = {
    wishList: [],
    loading: false,
    errors: null,
};

// thunk effects
export const fetchWishlist = createAsyncThunk(
    "wishlist/GetWishlist",
    async (user_id) => {
        try {
            const response = await server.get("/customer/wish-list", {
                params: { user_id: parseInt(user_id) , lang:lang},
            });
            if (!response.data.status) {
                document.documentElement.dir === 'rtl' ? toast.error("تم استرجاع الداتا") : toast.error(response.data.message);
                throw response.data;
            }
            return response.data;
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه ") : toast.error("Try again")
            throw error;
        }
    }
);
const lang = localStorage.getItem("lang")
export const addProductToWishList = createAsyncThunk(
    "wishlist/addProductToWishlist",
    async ({ user_id, product_id, callBack }) => {
        try {
            if( localStorage.getItem('user_id')) {
                const response = await server.post(`/customer/wish-list/add?lang=${lang}&user_id=${user_id}&product_id=${product_id}`);
                // if (response.data.status) {
                //     document.documentElement.dir === 'rtl' ? toast.success('تم إضافه المنتج إلى المفضله') : toast.success(response.data.message)
                //     // throw response.data;
                // }
                callBack && callBack()
                return response.data;
            } else{
                document.documentElement.dir === 'rtl' ? 
                swal({
                    title: "قم بالتسجيل أولا",
                    icon: "warning",
                    button: "OK!",
                })
                : 
                swal({
                    title: "please first login",
                    icon: "warning",
                    button: "OK!",
                });
                
                <Redirect to='/login'/>
            }
            
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error("حاول مره ثانيه ") : toast.error("Try again")
            throw error;
        }
    }
);

export const deleteProductFromWishList = createAsyncThunk(
    "wishlist/deleteProductFromWishlist",
    async ({ user_id, product_id , callBack }) => {
        try {
            const response = await server.delete("/customer/wish-list/remove", {
                params: { user_id, product_id }
            });
            if (!response.data.status) {
                document.documentElement.dir === 'rtl' ? toast.error('تم حذف المنتج من المفضله') : toast.error("Product deleted")
                throw response.data;
            }
            callBack && callBack()
            return response.data

        } catch (error) {
            document.documentElement.dir === 'rtl' ? 
            toast.error("الحذف لم يتم") : toast.error("Not removed");
            // toast.error("حدث خطأ");
            throw error;
        }
    }
);

// wishlist slice

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchWishlist.pending]: (state) => {
            state.loading = true;
        },
        [fetchWishlist.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.wishList = [...payload?.wishList];
            state.errors = null;
        },
        [fetchWishlist.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
        [addProductToWishList.pending]: (state) => {
            state.loading = true;
        },
        [addProductToWishList.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.errors = null;
        },
        [addProductToWishList.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
        [deleteProductFromWishList.pending]: (state) => {
            state.loading = true;
        },
        [deleteProductFromWishList.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.errors = null;
        },
        [deleteProductFromWishList.rejected]: (state, { error }) => {
            state.loading = false;
            state.errors = error;
        },
    },


});

export const wishlistActions = wishlistSlice.actions;

export default wishlistSlice.reducer;
