import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css'
import './Filter.css';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { RatingStar } from "rating-star";

const Filter = ({isFilterd ,  setIsFilterd}) => {
      const { t } = useTranslation();
      const history = useHistory()
      let [val, setVal] = useState({ min: 0, max: 2300 });
      let [yourCategID , setYourCategID] = useState('')
      let [yourSubCategID , setYourSubCategID] = useState('')
      const [rating, setRating] = useState(0);
      const [dataIsFiltered, setDataIsFiltered] = useState([]);

      const onRatingChange = score => {
            setRating(score);
      };
      const handleCategID = (e) => {
            const categID = e.target.value;
            setYourCategID(categID);
      }
      const handleSubCategID = (e) => {
            const subCategID = e.target.value;
            setYourSubCategID(subCategID);
      }

      const [allCategories , setAllCategories] = useState([]);
      const [subCategories , setSubCategories] = useState([]);
      const lang = localStorage.getItem("lang")
      const fetchCategories = async () => {
            const response = await axios(`https://zafaf-kw.com/dashboard/api/v1/categories?lang=${lang}`);
            setAllCategories(response.data.categories);
            return response.data.categories;
      }

      const fetchSubCategories = async () => {
            const response = await axios(`https://zafaf-kw.com/dashboard/api/v1/categories/subCategory?lang=${lang}&category_id=${yourCategID}`);
            setSubCategories(response.data.subCategories);
            return response.data.subCategories;
      }

      useEffect(() => {
            fetchCategories();
      } , [yourCategID , lang]);

      useEffect(() => {
            yourCategID && fetchSubCategories();
      } , [yourCategID , lang]);

      const handleFetchFilter = async () => {
            if(yourCategID) {
                  await axios.get(`https://zafaf-kw.com/dashboard/api/v1/products/fillter?lang=${lang}&category_id=${yourCategID}&sub_category_id=${yourSubCategID}&min=${val.min ? val.min : '0'}&max=${val.max ? val.max : '2300'}&rate=${rating ? rating : '5'}`)
                  .then((result) => {
                        if(result.data.status) {
                              setDataIsFiltered(result.data.products);
                              localStorage.setItem('dataIsFiltered' , JSON.stringify(result.data.products))
                              document.documentElement.dir === 'rtl' ? toast.success(result.data.message , {progress: undefined,theme: "light"}) : toast.success("Filter completed")
                              history.push(test)
                              // history.push(`/seemore?id=${yourCategID ?? yourSubCategID}`)
                              setIsFilterd(!isFilterd)
                        } else if (!result.data.status) {
                              document.documentElement.dir === 'rtl' ? toast.error("لم تتم التصفيه" , {progress: undefined,theme: "light"}) : toast.error("Filter not completed")
                        }
                  })
            } else {
                  document.documentElement.dir === 'rtl' ? toast.error("قم بإختيار الاقسام") : toast.error("Please select categories")
            }
      }
      const test = {
            pathname: '/ResultFilter',
            state: dataIsFiltered 
      } 
      const handleReset = () => {
            setIsFilterd(false);
            setTimeout(() => {
                  setIsFilterd(true)
            }, 500);
            document.documentElement.dir === 'rtl' ? toast.info("تم إعاده الضبط") : toast.info("Reset completed")
      }
      
      return (
            <div className='results-filteration'>
                  <div className='results-filteration-content'>
                        <div className='results-filteration-header'>
                              <span onClick={() => setIsFilterd(!isFilterd)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.25" height="15.246" viewBox="0 0 15.25 15.246">
                                          <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close" d="M20.717,18.912l5.447-5.447a1.276,1.276,0,0,0-1.8-1.8l-5.447,5.447L13.465,11.66a1.276,1.276,0,1,0-1.8,1.8l5.447,5.447-5.447,5.447a1.276,1.276,0,0,0,1.8,1.8l5.447-5.447,5.447,5.447a1.276,1.276,0,0,0,1.8-1.8Z" transform="translate(-11.285 -11.289)"/>
                                    </svg>
                              </span>
                              <h4>{t('filteration')}</h4>
                              <span onClick={handleReset}>{t('reset')}</span>
                        </div>
                        <div className='results-filteration-section'>
                              <Form.Group sm="12" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>{t('sec')}</Form.Label>
                                    <Form.Select label="{t('sec')}" className={document.documentElement.dir === 'rtl' && 'form-select-ar'} aria-label="Floating label select example" onChange={handleCategID}>
                                          <option>{t('sec-name')}</option>
                                          {
                                                allCategories.length > 0 && allCategories.map(option => (
                                                      <option key={option.id} value={option.id}>{option.name}</option>
                                                ))
                                          }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                          {t('Please-choose')} {t('sec')}.
                                    </Form.Control.Feedback>
                              </Form.Group>
                        </div>
                        <div className='results-filteration-section'>
                              <h6>{t('categ')}</h6>
                              <Form.Select className={document.documentElement.dir === 'rtl' && 'form-select-ar'} aria-label="Default select example" value={yourSubCategID} onChange={handleSubCategID}>
                                    <option>{t('categ-name')}</option>
                                    {
                                          subCategories.length > 0 && subCategories.map(option => (
                                                <option name={option.id} key={option.id} value={option.id}>{option.name}</option>
                                          )) 
                                    }
                              </Form.Select>
                        </div>
                        <div className='results-filteration-section'>
                              <h6>{t('salary-domain')}</h6>
                              <div className='salary-domain'>
                                    <div className='salary-domain-inp-range'>
                                          <InputRange
                                                required
                                                step={2}
                                                formatLabel={ value => null }
                                                draggableTrack={false}
                                                allowSameValues={false}
                                                maxValue={2300}
                                                value={val}
                                                minValue={0}
                                                onChange={setVal}
                                          />
                                    </div> 
                                    
                                    <div className='salary-domain-range'>
                                          <span>{`${val.max}`} {t('currency')}</span>
                                          <span>{`${val.min}`} {t('currency')}</span>
                                    </div>
                              </div>
                        </div>
                        <div className='results-filteration-section'>
                              <h6>{t('reviewing')}</h6>
                              <div className='filter-review'>
                                    <RatingStar
                                          id="clickable"
                                          clickable
                                          rating={rating}
                                          numberOfStar={5}
                                          maxScore={5}
                                          onRatingChange={onRatingChange}
                                    />
                              </div>
                        </div>
                        <div className='results-filteration-categ'></div>
                        <button 
                              className='results-filteration-btn' 
                              onClick={handleFetchFilter} 
                              type='submit'
                        >
                              {t('filter')}
                        </button>
                        {/* <Link to={location}>Show</Link> */}
                        {/* <Link onClick={handleFetchFilter} to={{
                              pathname: '/ResultFilterData',
                              state: { detail : dataIsFiltered ?? dataIsFiltered }
                        }}> My Link </Link> */}
                  </div>
            </div>
      )
}

export default Filter;