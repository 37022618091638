import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loading from '../Loading/Loading'
import ProductCard from '../productCard/productCard';
import './search_results.css';

const SearchResults = () => {
    const {t} = useTranslation();
    const lang = localStorage.getItem("lang");
    const history = useHistory();
    const querySearchValue = history.location.search.split("?")[1].split("=")[1]
    const [productsSearch ,  setProductsSearch] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [isSearchValue, setIsSearchValue] = useState(false);

    const handleSearch = async () => {
        await axios.get(`https://zafaf-kw.com/dashboard/api/v1/products/search?lang=${lang}&word=${querySearchValue}`)
        .then(result => {
            if(result.data.status) {
                setSpinner(false);
                setProductsSearch(result.data.products);
            } else if(!result.data.status) {
                setSpinner(false);
                setIsSearchValue(true)
                document.documentElement.dir === 'rtl' ? toast.error("أدخل إسم الخدمه التى تريد ان تبحث عنها") : toast.error("Enter service type which you want to search about it ")
            }
        })
    }
    useEffect(() => {
        handleSearch();
    } , [productsSearch]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='search-results'>
            <h2 className='search-results-title'>{t('search-results')}</h2>
            {/* <div className='search-results-desc'><b>{productsSearch.length}</b> <span>{t('products-from-search-results')}</span> <b>"{querySearchValue}"</b></div> */}
            <div className='search-results-desc'>"{productsSearch.length}"  {t('products-from-search-results')}  "{querySearchValue}"</div>
            <div className='search-results-content'>
                <div className='row'>
                    {spinner && <Loading />}
                    {
                        productsSearch && productsSearch?.length > 0 &&
                        productsSearch.map((cardSearch) => (
                            <div className="col-lg-2 col-md-4 col-sm-6 col-12 mb-3">
                                <ProductCard product={{...cardSearch , id:cardSearch?.id ?? cardSearch?.product_id}}/>
                            </div>
                        ))
                    }
                    {
                        isSearchValue && 
                        <div className='no-products'>
                            <span>{t('no-products')}</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchResults;