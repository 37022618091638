import React from 'react'
import Category from '../../Pages/Sections/Category'
import OurSlider from '../Slider/OurSlider'

const AllSections = () => {
    return (
        <>
            {/* <OurSlider /> */}
            <Category />
        </>
    )
}

export default AllSections