import React, { useEffect } from 'react'
import ExclusiveOffers from '../../Components/ExclusiveOffers/ExclusiveOffers'
import OfferPlus from '../../Components/OfferPlus/OfferPlus'
import Slider from '../../Components/Slider/OurSlider'
import ServiceProvider from '../../Components/Service_Provider/ServiceProvider'

const Offers = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <ServiceProvider />
            <OfferPlus />
        </>
    )
}

export default Offers