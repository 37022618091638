import React, { useEffect, useState } from 'react';
import Slider from '../Slider/OurSlider';
import { Link, useHistory } from 'react-router-dom'
import './SubCategory.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import ProductCard from '../productCard/productCard';
import ServiceProvider from '../Service_Provider/ServiceProvider';
import Loading from '../Loading/Loading';

const SubCategory = () => {
      const {t} = useTranslation();
      const [spinner, setSpinner] = useState(true);
      // get product id from params
      const history = useHistory();
      const params = history.location.search
      const blabla = params.split("?")[1].split("&").reduce((acc , curr) => {
            let temp = curr.split("=")
            acc[temp[0]] = temp[1]
            return {...acc}
      })
      const valid_id = blabla.split("=")[1];

      // Fetch sub categories
      const lang = localStorage.getItem("lang")
      const [subCateg , setSubCateg] = useState([]);
      const fetchSubCategories = async () => {
            await axios(`https://zafaf-kw.com/dashboard/api/v1/categories/subCategory?lang=${lang}&category_id=${valid_id}`)
            .then((result) => {
                  if (result.data.status) {
                        setSpinner(false)
                        setSubCateg(result.data.subCategories);
                  }
            })
      };

      useEffect(() => {
            fetchSubCategories();
      } , [lang]);

      useEffect(() => {
            window.scrollTo(0, 0)
      }, []);

      return (
            <div className='sub-category'>
                  <div className='sub-category-content'>
                        {spinner && <Loading />}
                        {
                              subCateg.length > 0 && subCateg.map((subCateg) => (
                                    <div className='invitation' key={subCateg?.id}>
                                          <div className='service-header'>
                                                <h4 data-aos="fade-left">{subCateg.name}</h4>
                                                <Link to={`/seemore?id=${subCateg?.id}`} data-aos="fade-right">{t('see_more')}</Link>
                                          </div>
                                          <div className='invitation-container' data-aos="fade-up">
                                                <Swiper
                                                      pagination={{
                                                            clickable: true,
                                                      }}
                                                      breakpoints={{
                                                            320: {
                                                                  slidesPerView: 1.5,
                                                                  spaceBetween: 10
                                                            },
                                                            480: {
                                                                  slidesPerView: 2.5,
                                                                  spaceBetween: 10
                                                            },
                                                            540: {
                                                                  slidesPerView: 2.5,
                                                                  spaceBetween: 10,
                                                            },
                                                            768: {
                                                                  slidesPerView: 3.5,
                                                                  spaceBetween: 10,
                                                            },
                                                            1024: {
                                                                  slidesPerView: 5.5,
                                                                  spaceBetween: 10,
                                                            },
                                                      }}
                                                      dir={document.documentElement.dir === 'rtl' ? 'rtl' : 'ltr'}
                                                      className="mySwiper"
                                                >
                                                      {
                                                            subCateg.products.length > 0 ? subCateg.products.map((categ , index) => (
                                                                  <SwiperSlide key={index}>
                                                                        <ProductCard product={{...categ , id:categ?.id ?? categ?.product_id}} />
                                                                  </SwiperSlide>
                                                            ))
                                                            :
                                                            <div className='no-products'>
                                                                  <span>{t('no-products')}</span>
                                                            </div>
                                                      }
                                                </Swiper>
                                          </div>
                                          
                                    </div>
                              )) 
                        }
                  </div>
                  <ServiceProvider />
            </div>
      )
}

export default SubCategory;