import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import './UploadProfileImage.css'
import {GrClose} from 'react-icons/gr';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

const UploadProfileImage = (props) => {
    const history = useHistory()
    const {t} = useTranslation();
    const userID = localStorage.getItem("user_id");
    const [selectedFile , setSelectedFile] = useState('')
    const handleSubmit = async () => {
        let formData = new FormData()
        formData.append('image' , selectedFile)
        formData.append('user_id' , userID)
        try {
            await axios.post(`https://zafaf-kw.com/dashboard/api/v1/auth/updateImage` , formData , {
                headers: { 
                    'enctype': 'multipart/form-data',
                },
            }).then((result) => {
                if (result.data.status) {
                    props.setShowUpload(!props.showUpload);
                    props.fetchProfileData()
                    document.documentElement.dir === 'rtl' ? toast.success("تم تحديث صوره البروفايل بنجاح") : toast.success("The profile picture has been successfully updated")
                    history.push('/')
                    history.push('/profile')
                } else if (!result.data.status) {
                    document.documentElement.dir === 'rtl' ? swal("يجب ان يكون حجم الصوره أقل من 2048 ك.ب") : swal("The image may not be greater than 2048 kilobytes.")
                }
                else {
                    document.documentElement.dir === 'rtl' ? swal("لم يتم تحديث الصوره الخاصه  بك") : swal("The profile picture has been successfully updated")
                }
            })
        } catch (error) {
            document.documentElement.dir === 'rtl' ? toast.error('فشل فى تعديل الصوره') : toast.error('Failed to submit files.');
        }
    };

    return (
        <div className='upload-files'>
            <div className='upload-files-content'>
                <div className='upload-files-info'>
                    <h2>{t('Upload-Files')}</h2>
                    <p>{t('upload-desc')}</p>
                </div>
                <div className="upload-form-container">
                    <div className='dropzone'>
                        <label htmlFor='select-files-to-upload' className='select-files-to-upload'>{t('Or')}</label>
                        <input
                            type="file"
                            id='select-files-to-upload'
                            style={{display: 'none'}}
                            value={""}
                            onChange={(e) => {
                                setSelectedFile(e.target.files[0])
                            }}
                        />
                    </div>

                    <div className="upload-manual"> 
                            {selectedFile && 
                            <div>
                                <span> {t('name-img')} : </span> <span> {selectedFile.name} </span>
                            </div>
                            }
                    </div>
                </div>
                {
                    selectedFile &&
                    <div className="submit-upload">
                        <button onClick={() => handleSubmit()}>{t('edit-img')}</button>
                    </div>
                }

                <span className="close-upload-model" onClick={() => props.setShowUpload(!props.showUpload)}>
                    <GrClose></GrClose>
                </span>
            </div>
        </div>
    )
}

export default UploadProfileImage